@media screen and (width <= 480px){
    .post1-section{
        border: solid 1px rgb(177, 177, 177);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 30px;
    }
    .comment-section{
        display: flex;
        flex-direction: row;
        /* border: solid 2px red; */
        margin-top: 20px;  
        text-align: start;
        margin-left: 20px;
    }
    .comment-section img{
        display: inline-block; 
        position: relative; 
        width: 70px; 
        height: 70px; 
        overflow: hidden; 
        border-radius: 50%; 
        /* margin-top: 15px; */
    }
    .comment-section h4{
        font-size: 1rem;
        /* border: solid 2px purple; */
        padding-top: 5px;
        padding-left: 10px;
        font-weight:700;
    }
    .comment-section h4 span{
        font-size: 1.4rem;
        font-weight: 800;
    }
    .comment-section h4 p{
        font-size: 0.9rem;
        font-weight: 400;
    }

    .picture1a-section img{
        margin-top: 20px;
        width: 310px;
        height: 280px;
    }
    .picture1b-section img{
        margin-top: 20px;
        width: 310px;
        height: 280px;
    }
    .text-section h3{
        margin-top: 30px;
        margin-left: 30px;
        font-size: 1.8rem;
        text-align: start;
    }
    .text-section h5{
        margin-top: 30px;
        margin-left: 10px;
        text-align: start;
        font-size: 1rem;
        font-weight: 350;
        padding-left: 20px;
        padding-right: 20px;  
    }

    .picture2-section img{
        margin-top: 30px;
        width: 65%;
        height: auto;
    }

    .text2-section h5{
        margin-top: 30px;
        margin-left: 5px;
        text-align: start;
        font-size: 1rem;
        font-weight: 350;
        padding-left: 20px;
        padding-right: 20px; 
        margin-bottom: 20px;  
    }

    .border-comment{
        border-top: solid 1px rgb(154, 152, 152);
        text-align: start;  
    }
    .border-comment h6{
        font-size: 0.8rem;
        font-weight: 700;
        padding-left: 30px;
        padding-bottom: 12px;
    }

    .sign-director{
        text-align: start;
    }

}
@media screen and (480px < width <= 768px){
    .post1-section{
        border: solid 1px rgb(177, 177, 177);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 30px;
    }
    .comment-section{
        display: flex;
        flex-direction: row;
        /* border: solid 2px red; */
        margin-top: 20px;  
        text-align: start;
        margin-left: 20px;
    }
    .comment-section img{
        display: inline-block; 
        position: relative; 
        width: 70px; 
        height: 70px; 
        overflow: hidden; 
        border-radius: 50%; 
        /* margin-top: 15px; */
    }
    .comment-section h4{
        font-size: 1rem;
        /* border: solid 2px purple; */
        padding-top: 5px;
        padding-left: 10px;
        font-weight:700;
    }
    .comment-section h4 span{
        font-size: 1.4rem;
        font-weight: 800;
    }
    .comment-section h4 p{
        font-size: 0.9rem;
        font-weight: 400;
    }

    .picture1a-section img{
        margin-top: 20px;
        width: 510px;
        height: 400px;
    }
    .picture1b-section img{
        margin-top: 20px;
        width: 550px;
        height: 380px;
    }
    .text-section h3{
        margin-top: 30px;
        margin-left: 30px;
        font-size: 1.8rem;
        text-align: start;
    }
    .text-section h5{
        margin-top: 30px;
        margin-left: 10px;
        text-align: start;
        font-size: 1rem;
        font-weight: 350;
        padding-left: 20px;
        padding-right: 20px;  
    }

    .picture2-section img{
        margin-top: 30px;
        width: 65%;
        height: auto;
    }

    .text2-section h5{
        margin-top: 30px;
        margin-left: 5px;
        text-align: start;
        font-size: 1rem;
        font-weight: 350;
        padding-left: 20px;
        padding-right: 20px; 
        margin-bottom: 20px;  
    }

    .border-comment{
        border-top: solid 1px rgb(154, 152, 152);
        text-align: start;  
    }
    .border-comment h6{
        font-size: 0.8rem;
        font-weight: 700;
        padding-left: 30px;
        padding-bottom: 12px;
    }

    .sign-director{
        text-align: start;
    }

}
@media screen and (768px < width <= 1024px){
    .post1-section{
        border: solid 1px rgb(177, 177, 177);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 30px;
    }
    .comment-section{
        display: flex;
        flex-direction: row;
        /* border: solid 2px red; */
        margin-top: 20px;  
        text-align: start;
        margin-left: 20px;
    }
    .comment-section img{
        display: inline-block; 
        position: relative; 
        width: 70px; 
        height: 70px; 
        overflow: hidden; 
        border-radius: 50%; 
        /* margin-top: 15px; */
    }
    .comment-section h4{
        font-size: 1rem;
        /* border: solid 2px purple; */
        padding-top: 5px;
        padding-left: 10px;
        font-weight:700;
    }
    .comment-section h4 span{
        font-size: 1.4rem;
        font-weight: 800;
    }
    .comment-section h4 p{
        font-size: 0.9rem;
        font-weight: 400;
    }

    .picture1a-section img{
        margin-top: 20px;
        width: 510px;
        height: 400px;
    }
    .picture1b-section img{
        margin-top: 20px;
        width: 550px;
        height: 380px;
    }
    .text-section h3{
        margin-top: 30px;
        margin-left: 30px;
        font-size: 1.8rem;
        text-align: start;
    }
    .text-section h5{
        margin-top: 30px;
        margin-left: 10px;
        text-align: start;
        font-size: 1rem;
        font-weight: 350;
        padding-left: 20px;
        padding-right: 20px;  
    }

    .picture2-section img{
        margin-top: 30px;
        width: 65%;
        height: auto;
    }

    .text2-section h5{
        margin-top: 30px;
        margin-left: 5px;
        text-align: start;
        font-size: 1rem;
        font-weight: 350;
        padding-left: 20px;
        padding-right: 20px; 
        margin-bottom: 20px;  
    }

    .border-comment{
        border-top: solid 1px rgb(154, 152, 152);
        text-align: start;  
    }
    .border-comment h6{
        font-size: 0.8rem;
        font-weight: 700;
        padding-left: 30px;
        padding-bottom: 12px;
    }

    .sign-director{
        text-align: start;
    }

}
@media screen and (1024px < width <=1600px){
    .post1-section{
        border: solid 1px rgb(177, 177, 177);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 30px;
    }
    .comment-section{
        display: flex;
        flex-direction: row;
        /* border: solid 2px red; */
        margin-top: 20px;  
        text-align: start;
        margin-left: 20px;
    }
    .comment-section img{
        display: inline-block; 
        position: relative; 
        width: 70px; 
        height: 70px; 
        overflow: hidden; 
        border-radius: 50%; 
        /* margin-top: 15px; */
    }
    .comment-section h4{
        font-size: 1rem;
        /* border: solid 2px purple; */
        padding-top: 5px;
        padding-left: 10px;
        font-weight:700;
    }
    .comment-section h4 span{
        font-size: 1.4rem;
        font-weight: 800;
    }
    .comment-section h4 p{
        font-size: 0.9rem;
        font-weight: 400;
    }

    .picture1a-section img{
        margin-top: 20px;
        width: 420px;
        height: 300px;
    }
    .picture1b-section img{
        margin-top: 20px;
        width: 420px;
        height: 300px;
    }
    .text-section h3{
        margin-top: 30px;
        margin-left: 30px;
        font-size: 1.8rem;
        text-align: start;
    }
    .text-section h5{
        margin-top: 30px;
        margin-left: 10px;
        text-align: start;
        font-size: 1rem;
        font-weight: 350;
        padding-left: 20px;
        padding-right: 20px;  
    }

    .picture2-section img{
        margin-top: 30px;
        width: 65%;
        height: auto;
    }

    .text2-section h5{
        margin-top: 30px;
        margin-left: 5px;
        text-align: start;
        font-size: 1rem;
        font-weight: 350;
        padding-left: 20px;
        padding-right: 20px; 
        margin-bottom: 20px;  
    }

    .border-comment{
        border-top: solid 1px rgb(154, 152, 152);
        text-align: start;  
    }
    .border-comment h6{
        font-size: 0.8rem;
        font-weight: 700;
        padding-left: 30px;
        padding-bottom: 12px;
    }

    .sign-director{
        text-align: start;
    }

}
@media screen and (width > 1600px){
    .post1-section{
        border: solid 1px rgb(177, 177, 177);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 30px;
    }
    .comment-section{
        display: flex;
        flex-direction: row;
        /* border: solid 2px red; */
        margin-top: 20px;  
        text-align: start;
        margin-left: 20px;
    }
    .comment-section img{
        display: inline-block; 
        position: relative; 
        width: 70px; 
        height: 70px; 
        overflow: hidden; 
        border-radius: 50%; 
        /* margin-top: 15px; */
    }
    .comment-section h4{
        font-size: 1rem;
        /* border: solid 2px purple; */
        padding-top: 5px;
        padding-left: 10px;
        font-weight:700;
    }
    .comment-section h4 span{
        font-size: 1.4rem;
        font-weight: 800;
    }
    .comment-section h4 p{
        font-size: 0.9rem;
        font-weight: 400;
    }

    .picture1a-section img{
        margin-top: 20px;
        width: 510px;
        height: 400px;
    }
    .picture1b-section img{
        margin-top: 20px;
        width: 550px;
        height: 380px;
    }
    .text-section h3{
        margin-top: 30px;
        margin-left: 30px;
        font-size: 1.8rem;
        text-align: start;
    }
    .text-section h5{
        margin-top: 30px;
        margin-left: 10px;
        text-align: start;
        font-size: 1rem;
        font-weight: 350;
        padding-left: 20px;
        padding-right: 20px;  
    }

    .picture2-section img{
        margin-top: 30px;
        width: 65%;
        height: auto;
    }

    .text2-section h5{
        margin-top: 30px;
        margin-left: 5px;
        text-align: start;
        font-size: 1rem;
        font-weight: 350;
        padding-left: 20px;
        padding-right: 20px; 
        margin-bottom: 20px;  
    }

    .border-comment{
        border-top: solid 1px rgb(154, 152, 152);
        text-align: start;  
    }
    .border-comment h6{
        font-size: 0.8rem;
        font-weight: 700;
        padding-left: 30px;
        padding-bottom: 12px;
    }

    .sign-director{
        text-align: start;
    }

}

