.nav-container{
    background-color: black;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 35px;
    box-shadow: 0 5px 15px 0 #fff;
    width: 100%;
    height: 80px;
    position: fixed;
    top:0px;
    left:50%;
    transform: translate(-50%);
    z-index: 9999;
    
}
.navbar-title{
    padding-left: 33px;
    padding-bottom: 12px;
}
.navbar-title h1{
    
    color: #FFD700;
    font-size: 2.1rem;
    cursor: pointer;
    
}
.navbar-title sub{
    font-weight: normal;
    color: #FFD700;
    font-size: 0.64rem;
    position: absolute;
    top:50px;
    left: 84px;  
}
.nav-menu{
    display: grid;
    grid-template-columns: repeat(5,auto);
    grid-gap:10px;
    list-style: none;
    align-items: center;
    
}
.icon-user{
    font-size: 1.8rem;
    padding: 0;
    margin: 0;
    color:#fff;
    
    margin-top: 20px;
    margin-right: 10px;
}

.login-user{
    font-size: 1rem;
    padding: 0;
    margin: 0;
    color:#fff;
    margin-right: 0px;
    margin-bottom: 0px; 
}

.name-user{
    font-size: 1rem;
    padding: 0;
    margin: 0;
    color:#fff;
    margin-right: 10px;
    margin-bottom: 20px; 
}
.nav-links{
    text-decoration: none;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.7rem 1rem;
    color:#fff; 
}
.nav-links i{
    padding-right: 5px;
}

.nav-links:hover {
    background-color: #01959a;
    color: #fff;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;
}

.menu-icons{
    display: none;
    
}

@media screen and (max-width:1280px) {
    .nav-container{
        width: 100%;
        height: 60px;    
    }
    .navbar-title h1{
        font-size: 1.8rem;
    }
    .navbar-title sub{
        font-size: 0.5rem;
        top:40px;
        left: 88px;  
    }
    .icon-user{
        font-size: 1.2rem;
        
        
    }
    .name-user{
        font-size: 0.9rem;
        font-weight: 600;
    }
    .nav-links{
        text-decoration: none;
        font-size: 0.9rem;
        font-weight: 600;
        padding: 0.7rem 1rem;
        color:#fff; 
    }
}

@media screen and (max-width: 850px) {
    .menu-icons{
        display: block;
        cursor: pointer;
    }
    .menu-icons i{
        font-size: 1.8rem;
        color: #FFD700;
        margin-right: 10px;
    }
    .nav-menu{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background: #fff;
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;
        left: -100%;
        opacity: 0;
        text-align: center;
        align-items: stretch;
        padding: 80px 0 30px 0;
        margin: 0;
        transition: 0.3s ease-in-out;
        border-radius: 8px;

    }
    .nav-links{
        color: black;
        display: block;
        width: 100%;
        font-size: 1rem;
        padding: 1rem 0;
    }
    .nav-links:hover{
        background-color: #01959a;
        transition: none;
    }
    .nav-menu.active{
        left: 0;
        opacity: 1;
        z-index: -1;
        transition: 0.3s ease-in-out;
    }
    
    
}