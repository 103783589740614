.gallery {
    margin-top: -10px;
    display: flex;
    flex-direction: column;
}

.img-gallery {
    margin-top: 15px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center; 
}

.img-gallery img {
    width:  25%;
    height: 25%;
    margin: 5px;
    border-radius: 6px;
    box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
}

.img-gallery img:hover {
    transform: scale(1.3);
}

@media screen and (width <= 850px){
    .img-gallery img {
        width:  80%;
        height: auto;
        margin: 5px;
        border-radius: 6px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
    }
    .img-gallery img:hover {
        transform: none;
    }
    
}

@media screen and (1024px < width <= 1200px){
    .img-gallery img {
        width:  30%;
        height: auto;
        margin: 5px;
        border-radius: 6px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
    }
    
}

@media screen and (1200px < width <= 1600px){
    .img-gallery img {
        width: 30%;
        height: auto;
        margin: 5px;
        border-radius: 6px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
    }
    
}

@media screen and (width > 1600px){
    .gallery h1{
        margin-top: 110px;
        font-size: 2.5rem;
    }
    .img-gallery img {
        width: 25%;
        height: auto;
        margin: 5px;
        border-radius: 6px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
    }
    
}
