@media screen and (width <= 480px){
    .destination{
        margin-top: 5rem;  
        /* border: 1px red solid; */
    }
    .first-section {
    }
    .first-section video {
        width: 92%;
        height: auto;
        box-shadow: 0 5px 15px 0 #222;
    }
    .text-ebrelle{
        color:black;
        text-align: center;
        align-items: center;
        padding: 10px;
    }
    .text-ebrelle h1 {
        font-size: 2rem;
    }
    .text-ebrelle p {
        font-size: 1rem;
    }
    .down-btn {
        margin-top: 10px;
        margin-bottom: 50px;
        align-items: center;
    }
    .down-btn a{ 
        text-decoration: none;
        border: 2px solid black;
        padding: 5px;
        font-weight: 600;
        font-size: 1rem;
    }
    .icon-section{ 
    }
    .icon-body{
       
    }
    .icon-title{
        /* border: solid blue 2px; */
        text-align: start;
        margin-left: 10px;
        margin-right: 10px;
    }
    .icon-title h1{
        font-size: 1rem;
        font-weight: 600;
    }

    .icon-title{
        text-align: start;
    }
    .icon-title img{
        width: 50px;
        border-radius: 6px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
        margin-right: 15px;
    }

    .gambar-ebrelle{  
    }
    .gambar-ebrelle img{
        width: 90%;
        height: auto;
        border-radius: 10px;
    }
   
    .first-des{
        display: flex;
        flex-direction: column;
        /* border: 1px blue solid;   */
        margin: 10px;
    }
    .des-text{
        text-align: start;
        font-size: 1rem;
    }
    .image {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        /* border: 1px blue solid; */
        text-align: center;
        /* margin-top: 10px;
        margin-bottom: 20px; */
    }
    .image img {
        /* flex: 1; */
        /* width: 20%;
        height: 50px; */
        width: 290px;
        height: 280px;
        object-fit: fill;
        border-radius: 6px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
        margin: 5px;
    }
    .des-text h2 {
        padding-bottom: 1rem;
    }

    .first-des-reverse{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        /* border: 1px blue solid;   */
        margin: 10px;
    }
}

@media screen and (480px < width <= 768px){
    .destination{
        margin-top: 5rem;  
        /* border: 1px red solid; */
    }
    .first-section {
    }
    .first-section video {
        width: 95%;
        height: auto;
        box-shadow: 0 5px 15px 0 #222;
    }
    .text-ebrelle{
        color:black;
        text-align: center;
        align-items: center;
        padding: 10px;
    }
    .text-ebrelle h1 {
        font-size: 2.2rem;
    }
    .text-ebrelle p {
        font-size: 1.2rem;
    }
    .down-btn {
        margin-top: 10px;
        margin-bottom: 50px;
        align-items: center;
    }
    .down-btn a{ 
        text-decoration: none;
        border: 2px solid black;
        padding: 5px;
        font-weight: 600;
        font-size: 1rem;
    }
    .icon-section{ 
        display: flex;
        flex-direction: row;
    }
    .icon-body{
       flex: 1;
    }
    .icon-title{
        /* border: solid blue 1px; */
        text-align: start;
        margin-left: 20px;
        margin-right: 10px;
    }
    .icon-title h1{
        font-size: 1.2rem;
        font-weight: 600;
    }

    .icon-title{
        text-align: start;
    }
    .icon-title img{
        width: 50px;
        border-radius: 6px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
        margin-right: 15px;
    }

    .gambar-ebrelle{  
        flex: 1;
        /* border: 1px blue solid; */
        margin-right: 20px;
    }
    .gambar-ebrelle img{
        width: 100%;
        height: auto;
        border-radius: 10px;
        text-align: center;
        margin-top: 30%;

    }
   
    .first-des{
        display: flex;
        flex-direction: column;
        /* border: 1px blue solid;   */
        margin: 20px;
    }
    .des-text{
        flex: 1;
        text-align: start;
        font-size: 1.2rem;
    }

    .image{  
        display: flex;
        flex-direction: row;
        justify-content: center;
        /* border: 1px blue solid; */
        text-align: center;
        margin-top: 10px;
        margin-bottom: 20px;
    }
    .image img{
        flex: 1;
        /* width: 10%; */
        width: 360px;
        height: 320px;
        object-fit:fill;
        border-radius: 6px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
        margin: 5px;
    }
    .des-text h2 {
        padding-bottom: 1rem;
    }

    .first-des-reverse{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        /* border: 1px blue solid;   */
        margin: 20px;
    }
}

@media screen and (768px < width < 1024px){
    .destination{
        margin-top: 5rem;  
        /* border: 1px red solid; */
    }
    .first-section {
    }
    .first-section video {
        width: 95%;
        height: auto;
        box-shadow: 0 5px 15px 0 #222;
    }
    .text-ebrelle{
        color:black;
        text-align: center;
        align-items: center;
        padding: 10px;
    }
    .text-ebrelle h1 {
        font-size: 2.2rem;
    }
    .text-ebrelle p {
        font-size: 1.2rem;
    }
    .down-btn {
        margin-top: 10px;
        margin-bottom: 50px;
        align-items: center;
    }
    .down-btn a{ 
        text-decoration: none;
        border: 2px solid black;
        padding: 5px;
        font-weight: 600;
        font-size: 1rem;
    }
    .icon-section{ 
        display: flex;
        flex-direction: row;
    }
    .icon-body{
       flex: 1;
    }
    .icon-title{
        /* border: solid blue 1px; */
        text-align: start;
        margin-left: 20px;
        margin-right: 10px;
    }
    .icon-title h1{
        font-size: 1.2rem;
        font-weight: 600;
    }

    .icon-title{
        text-align: start;
    }
    .icon-title img{
        width: 50px;
        border-radius: 6px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
        margin-right: 15px;
    }

    .gambar-ebrelle{  
        flex: 1;
        /* border: 1px blue solid; */
        margin-right: 20px;
    }
    .gambar-ebrelle img{
        width: 100%;
        height: auto;
        border-radius: 10px;
        text-align: center;
        margin-top: 30%;

    }
   
    .first-des{
        display: flex;
        flex-direction: column;
        /* border: 1px blue solid;   */
        margin: 20px;
    }
    .des-text{
        flex: 1;
        text-align: start;
        font-size: 1.2rem;
    }

    .image{  
        display: flex;
        flex-direction: row;
        justify-content: center;
        /* border: 1px blue solid; */
        text-align: end;
        margin-top: 10px;
        margin-bottom: 20px;
    }
    .image img{
        width: 360px;
        height: 380px;
        object-fit:fill;
        object-fit:fill;
        border-radius: 6px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
        margin: 5px;
    }
    .des-text h2 {
        padding-bottom: 1rem;
    }

    .first-des-reverse{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        /* border: 1px blue solid;   */
        margin: 20px;
    }
}

@media screen and (width = 1024px){
    .destination{
        margin-top: 5rem;  
        /* border: 1px red solid; */
    }
    .first-section {
    }
    .first-section video {
        width: 95%;
        height: auto;
        box-shadow: 0 5px 15px 0 #222;
    }
    .text-ebrelle{
        color:black;
        text-align: center;
        align-items: center;
        padding: 10px;
    }
    .text-ebrelle h1 {
        font-size: 2.2rem;
    }
    .text-ebrelle p {
        font-size: 1.2rem;
    }
    .down-btn {
        margin-top: 10px;
        margin-bottom: 50px;
        align-items: center;
    }
    .down-btn a{ 
        text-decoration: none;
        border: 2px solid black;
        padding: 5px;
        font-weight: 600;
        font-size: 1rem;
    }
    .icon-section{ 
        display: flex;
        flex-direction: row;
    }
    .icon-body{
       flex: 1;
    }
    .icon-title{
        /* border: solid blue 1px; */
        text-align: start;
        margin-left: 20px;
        margin-right: 10px;
    }
    .icon-title h1{
        font-size: 1.2rem;
        font-weight: 600;
    }

    .icon-title{
        text-align: start;
    }
    .icon-title img{
        width: 70px;
        border-radius: 6px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
        margin-right: 15px;
    }

    .gambar-ebrelle{  
        flex: 1;
        /* border: 1px blue solid; */
        margin-right: 40px;
    }
    .gambar-ebrelle img{
        width: 100%;
        height: auto;
        border-radius: 10px;
        text-align: center;
        margin-top: 20%;

    }
    .first-des{
        display: flex;
        flex-direction: column;
        /* border: 1px blue solid;   */
        margin: 20px;
    }
    .des-text{
        flex: 1;
        text-align: start;
        font-size: 1.2rem;
    }

    .image{  
        display: flex;
        flex-direction: row;
        justify-content: center;
        /* border: 1px blue solid; */
        text-align: end;
        margin-top: 10px;
        margin-bottom: 20px;
    }
    .image img{
        width: 410px;
        height: 380px;
        object-fit:fill;
        border-radius: 6px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
        margin: 5px;
    }
    .des-text h2 {
        padding-bottom: 1rem;
    }

    .first-des-reverse{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        /* border: 1px blue solid;   */
        margin: 20px;
    }
}

@media screen and (1024px < width <=1200px){
    .destination{
        margin-top: 5rem;  
        /* border: 1px red solid; */
    }
    .first-section {
    }
    .first-section video {
        width: 95%;
        height: auto;
        box-shadow: 0 5px 15px 0 #222;
    }
    .text-ebrelle{
        color:black;
        text-align: center;
        align-items: center;
        padding: 10px;
    }
    .text-ebrelle h1 {
        font-size: 2.2rem;
    }
    .text-ebrelle p {
        font-size: 1.2rem;
    }
    .down-btn {
        margin-top: 10px;
        margin-bottom: 50px;
        align-items: center;
    }
    .down-btn a{ 
        text-decoration: none;
        border: 2px solid black;
        padding: 5px;
        font-weight: 600;
        font-size: 1rem;
    }
    .icon-section{ 
        display: flex;
        flex-direction: row;
    }
    .icon-body{
       flex: 1;
    }
    .icon-title{
        /* border: solid blue 1px; */
        text-align: start;
        margin-left: 20px;
        margin-right: 10px;
    }
    .icon-title h1{
        font-size: 1.2rem;
        font-weight: 600;
    }

    .icon-title{
        text-align: start;
    }
    .icon-title img{
        width: 70px;
        border-radius: 6px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
        margin-right: 15px;
    }

    .gambar-ebrelle{  
        flex: 1;
        /* border: 1px blue solid; */
        margin-right: 40px;
    }
    .gambar-ebrelle img{
        width: 100%;
        height: auto;
        border-radius: 10px;
        text-align: center;
        margin-top: 20%;

    }
    .first-des{
        display: flex;
        flex-direction: row;
        /* border: 1px blue solid;   */
        margin: 30px;
    }
    .first-des .des-text{
        flex:1;
        text-align: start;
        /* border: 1px red solid; */
        padding-right: 1rem;
        padding-left: 0;
        
    }
    .des-text h2 {
        padding-bottom: 2rem;
        font-size: 2.2rem;
    }
    .des-text p {
        padding-bottom: 4rem;
        font-size: 1.3rem;
    }
    .image{  
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        /* border: 1px blue solid; */
        text-align: end;
        margin-top: 10px;
        margin-bottom: 20px;
    }
    .image img{
        flex: 1;
        width: 360px;
        height: 380px;
        object-fit:fill;
        border-radius: 6px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
        margin: 5px;
    }
    

    .first-des-reverse{
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        /* border: 1px blue solid;   */
        margin: 30px;
    }
    .first-des-reverse .des-text{
        flex:1;
        text-align: start;
        /* border: 1px red solid; */
        padding-right: 1rem;
        padding-left: 2rem;
        
    }
}

@media screen and (1200px < width <= 1600px){
    .destination{
        margin-top: 6rem;  
        /* border: 1px pink solid; */
    }
    .first-section {
    }
    .first-section video {
        width: 43%;
        height: auto;
        box-shadow: 0 5px 15px 0 #222;
    }
    .text-ebrelle{
        color:black;
        text-align: center;
        align-items: center;
        padding: 10px;
    }
    .text-ebrelle h1 {
        font-size: 2rem;
        
    }
    .text-ebrelle p {
        font-size: 1rem;
        /* border: 1px blue solid; */
        padding-left: 200px;
        padding-right: 200px;
    }
    .down-btn {
        margin-top: 10px;
        margin-bottom: 50px;
        align-items: center;
    }
    .down-btn a{ 
        text-decoration: none;
        border: 2px solid black;
        padding: 5px;
        font-weight: 600;
        font-size: 1rem;
    }
    .icon-section{ 
        display: flex;
        flex-direction: row;
    }
    .icon-body{
       flex: 1;
    }
    .icon-title{
        /* border: solid blue 1px; */
        margin-left: 110px;
        margin-right: 10px;
    }
    .icon-title h1{
        text-align: start;
        font-size: 1.2rem;
        font-weight: 600;
    }

    .icon-title{
        text-align: start;
    }
    .icon-title img{
        width: 48px;
        border-radius: 6px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
        margin-right: 15px;
    }

    .gambar-ebrelle{  
        flex: 1;
        /* border: 1px blue solid; */
        margin-right: 40px;
    }
    .gambar-ebrelle img{
        width: 80%;
        height: auto;
        border-radius: 10px;
        text-align: center;
        margin-top: 40px;

    }
    .first-des{
        display: flex;
        flex-direction: row;
        /* border: 1px blue solid;   */
        margin: 20px;
        margin-top: 50px;
    }
    .first-des .des-text{
        flex:1;
        text-align: start;
        /* border: 1px red solid; */
        padding-right: 1rem;
        padding-left: 0;
    }
    .first-des .des-text h2 {
        padding-top: 3rem;
        padding-bottom: 2rem;
        font-size: 2rem;
    }
    .des-text p {
        padding-bottom: 4rem;
        font-size: 1rem;
    }
    .image{  
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        /* border: 1px blue solid; */
        text-align: end;
        margin-top: 10px;
        margin-bottom: 20px;
    }
    .image img{
        flex: 2;
        width: 10%;
        height: auto;
        width: 290px;
        height: 320px;
        object-fit:fill;
        border-radius: 6px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
        margin: 8px;
    }
    

    .first-des-reverse{
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        /* border: 1px blue solid;   */
        margin: 30px;
        margin-top: 50px;
    }
    .first-des-reverse .des-text{
        flex:1;
        text-align: start;
        /* border: 1px red solid; */
        padding-right: 1rem;
        padding-left: 2rem;   
    }
    .first-des-reverse .des-text h2 {
        margin-top: -5rem;
        padding-bottom: 1rem;
        font-size: 2rem;
    }
}

@media screen and (width > 1600px){
    .destination{
        margin-top: 8rem;  
        /* border: 1px red solid; */
    }
    .first-section {
    }
    .first-section video {
        width: 45%;
        height: auto;
        box-shadow: 0 5px 15px 0 #222;
    }
    .text-ebrelle{
        color:black;
        text-align: center;
        align-items: center;
        padding: 10px;
        
    }
    .text-ebrelle h1 {
        font-size: 2.5rem;
        margin-top: 10px;
    }
    .text-ebrelle p {
        font-size: 1.5rem;
        padding-left: 210px;
        padding-right: 210px;
        margin-top: 10px;
    }
    .down-btn {
        margin-top: 50px;
        margin-bottom: 50px;
        align-items: center;
    }
    .down-btn a{ 
        text-decoration: none;
        border: 2px solid black;
        padding: 5px;
        font-weight: 600;
        font-size: 1.5rem;
        
    }
    .icon-section{ 
        /* border: 1px red solid; */
        display: flex;
        flex-direction: row;
    }
    .icon-body{
       flex: 1;
    }
    .icon-title{
        /* border: solid blue 1px; */
        margin-top: 20px;
        text-align: start;
        margin-left: 310px;
        
    }
    .icon-title h1{
        font-size: 1.5rem;
        font-weight: 600;
    }

    .icon-title img{
        width: 60px;
        border-radius: 6px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
        margin-right: 30px;
    }

    .gambar-ebrelle{  
        flex: 1;
        /* border: 1px blue solid; */
        margin-right: 0px;
        text-align: start;
    }
    .gambar-ebrelle img{
        width: 60%;
        height: auto;
        border-radius: 10px;
        margin-top: 140px;

    }
    .first-des{
        display: flex;
        flex-direction: row;
        /* border: 1px blue solid;   */
        margin: 20px;
    }
    .first-des .des-text{
        flex:1;
        text-align: start;
        /* border: 1px red solid; */
        padding-right: 1rem;
        padding-left: 0;
        margin-top: 20px;
    }
    .first-des .des-text h2 {
        padding-top: 3rem;
        padding-bottom: 2rem;
        font-size: 2.5rem;
    }
    .des-text p {
        padding-bottom: 4rem;
        font-size: 1.5rem;
    }
    .image{  
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
        /* border: 1px blue solid; */
        text-align: start;
        margin-top: 60px;
        margin-bottom: 50px;
    }
    .image img{
        flex: 1;
        /* width: 15%;
        height: auto; */
        width: 440px;
        height: 500px;
        object-fit:fill;
        border-radius: 6px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
        margin: 10px;
    }
    

    .first-des-reverse{
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        /* border: 1px blue solid;   */
        margin: 30px;
    }
    .first-des-reverse .des-text{
        flex:1;
        text-align: start;
        /* border: 1px red solid; */
        padding-right: 1rem;
        padding-left: 2rem;   
    }
    .first-des-reverse .des-text h2 {
        margin-top: -18rem;
        padding-bottom: 1rem;
        font-size: 2.5rem;
    }
}


