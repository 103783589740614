.listlevel2-container{
    list-style: none;
    
    
}
.listlevel2-body{
    /* border: 1px solid blue; */
    text-align: start;
    justify-content: start;
    margin: 10px 10px; 
}

.listlevel2-button{
    color: rgb(10, 57, 248);
    border: none;
    background-color: rgb(171, 168, 170);
    width: 100%;
    padding: 5px;
    text-align: center;
    font-size: 15px;
}
.listlevel2-button:hover{
    background-color: #01959a;
    color: #fff;
    border-radius: 4px;
    padding: 10px;
    list-style: none;
    cursor: pointer;
}

.listlevel2-text
{
    font-size: 24px;
    /* font-family: "Amiri Quran", serif; */
    /* font-family: "Amiri Quran", serif; */
    font-weight: 300;
    font-style: normal;
    text-align: center;
}