@media screen and (width <= 480px){
    .ebrelle-user {
        margin-top: -40px;
        display: flex;
        flex-direction: column;
    }
    .img-user {
        margin-top: 15px;
        margin-bottom: 50px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center; 
    }
    .img-user img {
        width:  87%;
        height: auto;
        margin: 5px;
        border-radius: 6px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
    }
    .img-user img:hover {
        /* transform: scale(1.3); */
    }

}

@media screen and (480px < width <= 768px){ 
    .ebrelle-user {
        margin-top: -40px;
        display: flex;
        flex-direction: column;
    }
    .img-user {
        margin-top: 15px;
        margin-bottom: 50px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center; 
    }
    .img-user img {
        width:  350px;
        height: 300px;
        margin: 5px;
        border-radius: 6px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
    }
    .img-user img:hover {
        transform: scale(1.3);
    }

}

@media screen and (480px < width <= 768px){ 
    .ebrelle-user {
        margin-top: -40px;
        display: flex;
        flex-direction: column;
    }
    .img-user {
        margin-top: 15px;
        margin-bottom: 50px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center; 
    }
    .img-user img {
        width:  350px;
        height: 300px;
        margin: 5px;
        border-radius: 6px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
    }
    .img-user img:hover {
        /* transform: scale(1.3); */
    }

}

@media screen and (768px < width <= 1024px){ 
    .ebrelle-user {
        margin-top: -40px;
        display: flex;
        flex-direction: column;
    }
    .img-user {
        margin-top: 15px;
        margin-bottom: 50px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center; 
    }
    .img-user img {
        width:  450px;
        height: 300px;
        margin: 5px;
        border-radius: 6px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
    }
    .img-user img:hover {
        /* transform: scale(1.3); */
    }
    
}

@media screen and (1024px < width <=1200px){ 
    .ebrelle-user {
        margin-top: -40px;
        display: flex;
        flex-direction: column;
    }
    .img-user {
        margin-top: 15px;
        margin-bottom: 50px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center; 
    }
    .img-user img {
        width:  550px;
        height: 400px;
        margin: 5px;
        border-radius: 6px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
    }
    .img-user img:hover {
        /* transform: scale(1.3); */
    }
        

}

@media screen and (1200px < width <= 1600px){ 
    .ebrelle-user {
        margin-top: -40px;
        display: flex;
        flex-direction: column;
    }
    .img-user {
        margin-top: 15px;
        margin-bottom: 50px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center; 
    }
    .img-user img {
        width:  400px;
        height: 300px;
        margin: 5px;
        border-radius: 6px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
    }
    .img-user img:hover {
        transform: scale(1.3);
    }
        

}

@media screen and (width > 1600px){ 
    .ebrelle-user {
        margin-top: -40px;
        display: flex;
        flex-direction: column;
    }
    .img-user {
        margin-top: 15px;
        margin-bottom: 50px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center; 
    }
    .img-user img {
        width:  550px;
        height: 400px;
        margin: 5px;
        border-radius: 6px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
    }
    .img-user img:hover {
        transform: scale(1.3);
    }
        

}
