.footer {
    padding: 60px 35px;
    background: #000;
    color: #fff; 
    
}

.top {
    display: flex;
    align-items:center ;
    text-align: start;
    justify-content: space-between;
    flex-wrap: wrap;
}
.top i {
    color: #fff;
    font-size: 2rem;
    margin-left: 1.2rem;
    margin-right: 0rem;
}

.top i:hover {
    color: blue;
}

.laptop-logo {
    
}
.laptop-logo h1{
    font-weight: bold;
    color: #FFD700;
    font-size: 2.1rem; 
}
.laptop-logo sub{
    font-weight: normal;
    color: #FFD700;
    font-size: 0.6rem;
    
    position: relative;
    top:5px;
    left: -123px;
}

.bottom {
    text-align: start;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    flex-wrap: wrap;  
}
.more-info {
    text-align: start;  
}
.manufacture{
    text-align: end;
}
.bottom h4{
    font-weight: 700;
    padding: 1rem 0 0.8rem 0;
    /*border: solid 2px blue;*/
}
.bottom h5{
    padding: 0;
    margin-top: -10px;
    font-weight: 300;
}


@media screen and (max-width:1780px) {
    .manufacture{
        text-align: start;
    }
}

@media screen and (max-width:860px) {
    .footer {
        padding: 10px 10px; 
    }
    .top {
        display: flex;
        align-items:center ;
        text-align: start;
        justify-content: space-between;
        flex-wrap: wrap;
        
    }
    .top i {
        color: #fff;
        font-size: 1.6rem;
        margin-right: 0.3rem;
        margin-left: 0rem;
        
    }
    .laptop-logo sub{
        font-weight: normal;
        color: #FFD700;
        font-size: 0.6rem;
        
        position: relative;
        top:6px;
        left: -103px;
    }
}