.list-container{
    list-style: none;
}
.list-body{
    /* border: 1px solid blue; */
    text-align: start;
    margin: 10px 10px;
}

.list-button{
    color: rgb(10, 57, 248);
    border: none;
    background-color: rgb(171, 168, 170);
    padding: 5px;
    width: 100%;
    text-align: start;
    font-size: 15px;
}
.list-button:hover{
    background-color: #01959a;
    color: #fff;
    border-radius: 4px;
    padding: 10px;
    list-style: none;
    cursor: pointer;
}