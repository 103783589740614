@media screen and (width <= 480px){
    .contact-container {
        margin: 3.8rem 0.75rem;
        color: #2a2a2a;
        /* border: red solid 1px; */
        
    }
    .meet{
        font-size: 1.8rem;
        padding-top: 5px;
        padding-bottom: 5px;
        border: #2a2a2a solid 1px;
        color: white;
        background-color: #44555f ;
       
    }
    
    .ceo-col{
        display: flex;
        flex-direction: column;
        justify-content:center;
    }
    .afifah{
        /* border: 1px red solid; */
        margin-top: 20px;
    }
    .afifah img{
        display: inline-block; 
        position: relative; 
        width: 150px; 
        height: 150px; 
        overflow: hidden; 
        border-radius: 50%; 
    }
    .afifah h2{
        font-size: 1rem;
        font-weight: 600;
        padding-right: 15px;
    }
    .afifah h3{
        font-size: 0.9rem;
        font-weight: 400;
    }
    .faris{
        /* border: 1px red solid; */
        /* margin-top: -20px;
        margin-right: 70px;
        padding: 10px; */
        margin-top: 20px;
    }
    .faris img{
        display: inline-block; 
        position: relative; 
        width: 150px; 
        height: 150px; 
        overflow: hidden; 
        border-radius: 50%; 
    }
    .faris h2{
        font-size: 1rem;
        font-weight: 600;
    }
    .faris h3{
        font-size: 0.9rem;
        font-weight: 400;
    }

    .meet-board{
        margin-top: 60px;
        font-size: 1.8rem;
        padding-top: 5px;
        padding-bottom: 5px;
        border: #2a2a2a solid 1px;
        color: white;
        background-color: #44555f ;
    }
    .ceo-col2{
        display: flex;
        flex-direction: column;
        justify-content:center;
    }

    .zhariff{
        flex: 1;
        /* border: 1px red solid; */
        margin-top: 20px;
        
    }
    .zhariff img{
        display: inline-block; 
        position: relative; 
        width: 150px; 
        height: 150px; 
        overflow: hidden; 
        border-radius: 50%; 
        margin: 30px;
    }
    .zhariff h2{
        font-size: 1rem;
        font-weight: 600;
        
    }
    .zhariff h3{
        font-size: 0.9rem;
        font-weight: 400;
    }
    .meet-contact{
        font-size: 1.8rem;
        padding-top: 5px;
        padding-bottom: 5px;
        border: #2a2a2a solid 1px;
        color: white;
        background-color: #44555f ;
        margin-top: 30px; 
    }

    .quotation h7{
        font-size: 1.5rem;
        font-weight: bold;
    }
    .quotation a{
        font-size: 1.5rem;
        font-weight: bold;
    }
    .quotation img{
        margin-top: 10px;
        text-align: center;
        width: 100%;
        height: auto;
    }
    .address-section{
        display: flex;
        flex-direction: column;
        justify-content:space-around;
        padding: 5px;
        text-align: center;
        
    }
    .address-section h3{
        border: 1px black solid;
        padding: 10px;
        border-radius: 15px;
        background-color: black;
        color: white;
        text-align: start;
        font-size: 1rem;
        font-weight: 400;
        margin: 5px;
    }

    .contact-container h1{
        font-size: 1.8rem;
        
    }

}
@media screen and (480px < width <= 768px){
    .contact-container {
        margin: 3.8rem 2rem;
        color: #2a2a2a;
        /* border: red solid 1px; */
        
    }
    .meet{
        font-size: 1.8rem;
        padding-top: 5px;
        padding-bottom: 5px;
        border: #2a2a2a solid 1px;
        color: white;
        background-color: #44555f ; 
    }
    
    .ceo-col{
        display: flex;
        flex-direction: row;
        justify-content:center;
    }
    .afifah{
        /* border: 1px red solid; */
        margin-top: 20px;
    }
    .afifah img{
        display: inline-block; 
        position: relative; 
        width: 150px; 
        height: 150px; 
        overflow: hidden; 
        border-radius: 50%; 
    }
    .afifah h2{
        font-size: 1rem;
        font-weight: 600;
        padding-right: 25px;
        padding-left: 25px;
    }
    .afifah h3{
        font-size: 0.9rem;
        font-weight: 400;
    }
    .faris{
        margin-right: 10px;
        margin-top: 20px;
    }
    .faris img{
        display: inline-block; 
        position: relative; 
        width: 150px; 
        height: 150px; 
        overflow: hidden; 
        border-radius: 50%; 
    }
    .faris h2{
        font-size: 1rem;
        font-weight: 600;
    }
    .faris h3{
        font-size: 0.9rem;
        font-weight: 400;
    }

    .meet-board{
        margin-top: 60px;
        font-size: 1.8rem;
        padding-top: 5px;
        padding-bottom: 5px;
        border: #2a2a2a solid 1px;
        color: white;
        background-color: #44555f ;
    }
    .ceo-col2{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-around;
        /* border: 1px blue solid; */
        
    }

    .zhariff{
        flex: 1;
        /* border: 1px red solid; */
        margin-top: 20px;
        
    }
    .zhariff img{
        display: inline-block; 
        position: relative; 
        width: 150px; 
        height: 150px; 
        overflow: hidden; 
        border-radius: 50%; 
        margin: 30px;
    }
    .zhariff h2{
        font-size: 1rem;
        font-weight: 600;
        
    }
    .zhariff h3{
        font-size: 0.9rem;
        font-weight: 400;
    }

    .meet-contact{
        font-size: 1.8rem;
        padding-top: 5px;
        padding-bottom: 5px;
        border: #2a2a2a solid 1px;
        color: white;
        background-color: #44555f ;
        margin-top: 30px; 
    }

    .quotation h7{
        font-size: 1.5rem;
        font-weight: bold;
    }
    .quotation a{
        font-size: 1.5rem;
        font-weight: bold;
    }
    .quotation img{
        margin-top: 10px;
        text-align: center;
        width: 580px;
        height: 300px;
    }
    .address-section{
        display: flex;
        flex-direction: column;
        justify-content:center;
        padding-left: 150px;
        padding-right: 150px;  
    }
    .address-section h3{
        border: 1px black solid;
        padding: 10px;
        border-radius: 15px;
        background-color: black;
        color: white;
        text-align: center;
        font-size: 1rem;
        font-weight: 400;
        margin: 5px;

    }

    .contact-container h1{
        font-size: 1.8rem;
    }

}
@media screen and (768px < width <= 1024px){
    .contact-container {
        margin: 3.8rem 1rem;
        color: #2a2a2a;
        /* border: red solid 1px; */
        
    }
    .meet{
        font-size: 1.8rem;
        padding-top: 5px;
        padding-bottom: 5px;
        border: #2a2a2a solid 1px;
        color: white;
        background-color: #44555f ; 
    }
    
    .ceo-col{
        display: flex;
        flex-direction: row;
        justify-content:center;
    }
    .afifah{
        /* border: 1px red solid; */
        margin-top: 20px;
    }
    .afifah img{
        display: inline-block; 
        position: relative; 
        width: 150px; 
        height: 150px; 
        overflow: hidden; 
        border-radius: 50%; 
    }
    .afifah h2{
        font-size: 1rem;
        font-weight: 600;
        padding-right: 25px;
        padding-left: 25px;
    }
    .afifah h3{
        font-size: 0.9rem;
        font-weight: 400;
    }
    .faris{
        margin-right: 10px;
        margin-top: 20px;
    }
    .faris img{
        display: inline-block; 
        position: relative; 
        width: 150px; 
        height: 150px; 
        overflow: hidden; 
        border-radius: 50%; 
    }
    .faris h2{
        font-size: 1rem;
        font-weight: 600;
    }
    .faris h3{
        font-size: 0.9rem;
        font-weight: 400;
    }

    .meet-board{
        margin-top: 60px;
        font-size: 1.8rem;
        padding-top: 5px;
        padding-bottom: 5px;
        border: #2a2a2a solid 1px;
        color: white;
        background-color: #44555f ;
    }
    .ceo-col2{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-around;
        /* border: 1px blue solid; */
        
    }

    .zhariff{
        flex: 1;
        /* border: 1px red solid; */
        margin-top: 20px;
        
    }
    .zhariff img{
        display: inline-block; 
        position: relative; 
        width: 150px; 
        height: 150px; 
        overflow: hidden; 
        border-radius: 50%; 
        margin: 30px;
    }
    .zhariff h2{
        font-size: 1rem;
        font-weight: 600;
        
    }
    .zhariff h3{
        font-size: 0.9rem;
        font-weight: 400;
    }

    .meet-contact{
        font-size: 1.8rem;
        padding-top: 5px;
        padding-bottom: 5px;
        border: #2a2a2a solid 1px;
        color: white;
        background-color: #44555f ;
        margin-top: 30px; 
    }

    .quotation h7{
        font-size: 1.5rem;
        font-weight: bold;
    }
    .quotation a{
        font-size: 1.5rem;
        font-weight: bold;
    }
    .quotation img{
        margin-top: 10px;
        text-align: center;
        /* width: 580px;
        height: 300px; */
        width: 100%;
        /* max-width: 580px; */
        height: auto;
        
    }
    .address-section{
        display: flex;
        flex-direction: column;
        justify-content:center;
        padding-left: 200px;
        padding-right: 200px;  
    }
    .address-section h3{
        border: 1px black solid;
        padding: 10px;
        border-radius: 15px;
        background-color: black;
        color: white;
        text-align: center;
        font-size: 1rem;
        font-weight: 400;
        margin: 5px;

    }

    .contact-container h1{
        font-size: 1.8rem;
    }

}

@media screen and (1024px < width <=1600px){
    .contact-container {
        margin: 3.9rem 10rem;
        color: #2a2a2a;
        /* border: red solid 1px; */
        
    }
    .meet{
        font-size: 1.8rem;
        padding-top: 5px;
        padding-bottom: 5px;
        border: #2a2a2a solid 1px;
        color: white;
        background-color: #44555f ; 
    }
    
    .ceo-col{
        display: flex;
        flex-direction: row;
        justify-content:center;
    }
    .afifah{
        /* border: 1px red solid; */
        margin-top: 20px;
    }
    .afifah img{
        display: inline-block; 
        position: relative; 
        width: 150px; 
        height: 150px; 
        overflow: hidden; 
        border-radius: 50%; 
    }
    .afifah h2{
        font-size: 1rem;
        font-weight: 600;
        padding-right: 35px;
        padding-left: 35px;
    }
    .afifah h3{
        font-size: 0.9rem;
        font-weight: 400;
    }
    .faris{
        margin-right: 10px;
        margin-top: 20px;
    }
    .faris img{
        display: inline-block; 
        position: relative; 
        width: 150px; 
        height: 150px; 
        overflow: hidden; 
        border-radius: 50%; 
    }
    .faris h2{
        font-size: 1rem;
        font-weight: 600;
        padding-right: 35px;
        padding-left: 35px;
    }
    .faris h3{
        font-size: 0.9rem;
        font-weight: 400;
    }

    .meet-board{
        margin-top: 60px;
        font-size: 1.8rem;
        padding-top: 5px;
        padding-bottom: 5px;
        border: #2a2a2a solid 1px;
        color: white;
        background-color: #44555f ;
    }
    .ceo-col2{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-around;
        /* border: 1px blue solid; */
        
    }

    .zhariff{
        flex: 1;
        /* border: 1px red solid; */
        margin-top: 20px;
        
    }
    .zhariff img{
        display: inline-block; 
        position: relative; 
        width: 150px; 
        height: 150px; 
        overflow: hidden; 
        border-radius: 50%; 
        margin: 30px;
    }
    .zhariff h2{
        font-size: 1rem;
        font-weight: 600;
        
    }
    .zhariff h3{
        font-size: 0.9rem;
        font-weight: 400;
    }

    .meet-contact{
        font-size: 1.8rem;
        padding-top: 5px;
        padding-bottom: 5px;
        border: #2a2a2a solid 1px;
        color: white;
        background-color: #44555f ;
        margin-top: 30px; 
    }

    .quotation h7{
        font-size: 1.5rem;
        font-weight: bold;
    }
    .quotation a{
        font-size: 1.5rem;
        font-weight: bold;
    }
    .quotation img{
        margin-top: 10px;
        text-align: center;
        width: 580px;
        height: 300px;
    }
    .address-section{
        display: flex;
        flex-direction: column;
        justify-content:center;
        padding-left: 200px;
        padding-right: 200px;  
    }
    .address-section h3{
        border: 1px black solid;
        padding: 10px;
        border-radius: 15px;
        background-color: black;
        color: white;
        text-align: center;
        font-size: 1rem;
        font-weight: 400;
        margin: 5px;

    }

    .contact-container h1{
        font-size: 1.8rem;
    }

}
@media screen and (width > 1600px){
    .contact-container {
        margin: 5rem 25rem;
        color: #2a2a2a;
        /* border: red solid 1px; */
        
    }
    .meet{
        font-size: 1.8rem;
        padding-top: 5px;
        padding-bottom: 5px;
        border: #2a2a2a solid 1px;
        color: white;
        background-color: #44555f ; 
    }
    
    .ceo-col{
        display: flex;
        flex-direction: row;
        justify-content:center;
    }
    .afifah{
        /* border: 1px red solid; */
        margin-top: 20px;
    }
    .afifah img{
        display: inline-block; 
        position: relative; 
        width: 200px; 
        height: 230px; 
        overflow: hidden; 
        border-radius: 50%; 
    }
    .afifah h2{
        font-size: 1rem;
        font-weight: 600;
        padding-right: 45px;
        padding-left: 45px;
    }
    .afifah h3{
        font-size: 0.9rem;
        font-weight: 400;
        
    }
    .faris{
        margin-right: 10px;
        margin-top: 20px;
    }
    .faris img{
        display: inline-block; 
        position: relative; 
        width: 200px; 
        height: 230px; 
        overflow: hidden; 
        border-radius: 50%; 
    }
    .faris h2{
        font-size: 1rem;
        font-weight: 600;
        padding-right: 45px;
        padding-left: 45px;
    }
    .faris h3{
        font-size: 0.9rem;
        font-weight: 400;
    }

    .meet-board{
        margin-top: 60px;
        font-size: 1.8rem;
        padding-top: 5px;
        padding-bottom: 5px;
        border: #2a2a2a solid 1px;
        color: white;
        background-color: #44555f ;
    }
    .ceo-col2{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-around;
        /* border: 1px blue solid; */
        
    }

    .zhariff{
        flex: 1;
        /* border: 1px red solid; */
        margin-top: 20px;
        
    }
    .zhariff img{
        display: inline-block; 
        position: relative; 
        width: 200px; 
        height: 230px; 
        overflow: hidden; 
        border-radius: 50%; 
        margin: 30px;
    }
    .zhariff h2{
        font-size: 1rem;
        font-weight: 600;
        
    }
    .zhariff h3{
        font-size: 0.9rem;
        font-weight: 400;
    }

    .meet-contact{
        font-size: 1.8rem;
        padding-top: 5px;
        padding-bottom: 5px;
        border: #2a2a2a solid 1px;
        color: white;
        background-color: #44555f ;
        margin-top: 30px; 
    }

    .quotation h7{
        font-size: 1.5rem;
        font-weight: bold;
    }
    .quotation a{
        font-size: 1.5rem;
        font-weight: bold;
    }
    .quotation img{
        margin-top: 10px;
        text-align: center;
        width: 580px;
        height: 300px;
    }
    .address-section{
        display: flex;
        flex-direction: column;
        justify-content:center;
        padding-left: 200px;
        padding-right: 200px;  
    }
    .address-section h3{
        border: 1px black solid;
        padding: 10px;
        border-radius: 15px;
        background-color: black;
        color: white;
        text-align: center;
        font-size: 1rem;
        font-weight: 400;
        margin: 5px;

    }

    .contact-container h1{
        font-size: 1.8rem;
    }

}