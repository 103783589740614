@media screen and (width <= 480px){
    .post1-section{
        border: solid 1px rgb(177, 177, 177);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 30px;
    }
    .comment-section{
        display: flex;
        flex-direction: row;
        /* border: solid 2px red; */
        margin-top: 20px;  
        text-align: start;
        margin-left: 20px;
    }
    .comment-section img{
        display: inline-block; 
        position: relative; 
        width: 70px; 
        height: 70px; 
        overflow: hidden; 
        border-radius: 50%; 
        /* margin-top: 15px; */
    }
    .comment-section h4{
        font-size: 1rem;
        /* border: solid 2px purple; */
        padding-top: 5px;
        padding-left: 10px;
        font-weight:700;
    }
    .comment-section h4 span{
        font-size: 1.4rem;
        font-weight: 800;
    }
    .comment-section h4 p{
        font-size: 0.9rem;
        font-weight: 400;
    }

    .picture4-section img{
        margin-top: 20px;
        width: 310px;
        height: 400px;
    }
    .text-section h3{
        margin-top: 30px;
        margin-left: 30px;
        font-size: 1.8rem;
        text-align: start;
    }
    .text-section h5{
        margin-top: 30px;
        margin-left: 10px;
        text-align: start;
        font-size: 1rem;
        font-weight: 350;
        padding-left: 20px;
        padding-right: 20px;  
    }
    .text-section h5 i{
        font-weight: bold;
    }

    .picture2-section img{
        margin-top: 30px;
        width: 690px;
        height: 400px;
    }

    .text2-section h5{
        margin-top: 30px;
        margin-left: 5px;
        text-align: start;
        font-size: 1rem;
        font-weight: 350;
        padding-left: 20px;
        padding-right: 20px; 
        margin-bottom: 20px;  
    }

    .text2-section i{
    cursor: pointer;
    color: blue;  
    }
    .border6-comment{
        border-top: solid 1px blue;
        text-align: start;  
    }
    .border6-comment h6{
        font-size: 0.8rem;
        font-weight: 700;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px; 
    }

    .sign-director{
        text-align: start;
    }
}
@media screen and (480px < width <= 768px){
    .post1-section{
        border: solid 1px rgb(177, 177, 177);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 30px;
    }
    .comment-section{
        display: flex;
        flex-direction: row;
        /* border: solid 2px red; */
        margin-top: 20px;  
        text-align: start;
        margin-left: 20px;
    }
    .comment-section img{
        display: inline-block; 
        position: relative; 
        width: 70px; 
        height: 70px; 
        overflow: hidden; 
        border-radius: 50%; 
        /* margin-top: 15px; */
    }
    .comment-section h4{
        font-size: 1rem;
        /* border: solid 2px purple; */
        padding-top: 5px;
        padding-left: 10px;
        font-weight:700;
    }
    .comment-section h4 span{
        font-size: 1.4rem;
        font-weight: 800;
    }
    .comment-section h4 p{
        font-size: 0.9rem;
        font-weight: 400;
    }

    .picture4-section img{
        margin-top: 20px;
        width: 420px;
        height: 580px;
    }
    .text-section h3{
        margin-top: 30px;
        margin-left: 30px;
        font-size: 1.8rem;
        text-align: start;
    }
    .text-section h5{
        margin-top: 30px;
        margin-left: 10px;
        text-align: start;
        font-size: 1rem;
        font-weight: 350;
        padding-left: 20px;
        padding-right: 20px;  
    }
    .text-section h5 i{
        font-weight: bold;
    }

    .picture2-section img{
        margin-top: 30px;
        width: 690px;
        height: 400px;
    }

    .text2-section h5{
        margin-top: 30px;
        margin-left: 5px;
        text-align: start;
        font-size: 1rem;
        font-weight: 350;
        padding-left: 20px;
        padding-right: 20px; 
        margin-bottom: 20px;  
    }

    .text2-section i{
    cursor: pointer;
    color: blue;  
    }
    .border6-comment{
        border-top: solid 1px blue;
        text-align: start;  
    }
    .border6-comment h6{
        font-size: 0.8rem;
        font-weight: 700;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px; 
    }

    .sign-director{
        text-align: start;
    }
}
@media screen and (768px < width <= 1024px){
    .post1-section{
        border: solid 1px rgb(177, 177, 177);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 30px;
    }
    .comment-section{
        display: flex;
        flex-direction: row;
        /* border: solid 2px red; */
        margin-top: 20px;  
        text-align: start;
        margin-left: 20px;
    }
    .comment-section img{
        display: inline-block; 
        position: relative; 
        width: 70px; 
        height: 70px; 
        overflow: hidden; 
        border-radius: 50%; 
        /* margin-top: 15px; */
    }
    .comment-section h4{
        font-size: 1rem;
        /* border: solid 2px purple; */
        padding-top: 5px;
        padding-left: 10px;
        font-weight:700;
    }
    .comment-section h4 span{
        font-size: 1.4rem;
        font-weight: 800;
    }
    .comment-section h4 p{
        font-size: 0.9rem;
        font-weight: 400;
    }

    .picture4-section img{
        margin-top: 20px;
        width: 550px;
        height: 680px;
    }
    .text-section h3{
        margin-top: 30px;
        margin-left: 30px;
        font-size: 1.8rem;
        text-align: start;
    }
    .text-section h5{
        margin-top: 30px;
        margin-left: 10px;
        text-align: start;
        font-size: 1rem;
        font-weight: 350;
        padding-left: 20px;
        padding-right: 20px;  
    }
    .text-section h5 i{
        font-weight: bold;
    }

    .picture2-section img{
        margin-top: 30px;
        width: 690px;
        height: 400px;
    }

    .text2-section h5{
        margin-top: 30px;
        margin-left: 5px;
        text-align: start;
        font-size: 1rem;
        font-weight: 350;
        padding-left: 20px;
        padding-right: 20px; 
        margin-bottom: 20px;  
    }

    .text2-section i{
    cursor: pointer;
    color: blue;  
    }
    .border6-comment{
        border-top: solid 1px blue;
        text-align: start;  
    }
    .border6-comment h6{
        font-size: 0.8rem;
        font-weight: 700;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px; 
    }

    .sign-director{
        text-align: start;
    }
}
@media screen and (1024px < width <=1600px){
    .post1-section{
        border: solid 1px rgb(177, 177, 177);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 30px;
    }
    .comment-section{
        display: flex;
        flex-direction: row;
        /* border: solid 2px red; */
        margin-top: 20px;  
        text-align: start;
        margin-left: 20px;
    }
    .comment-section img{
        display: inline-block; 
        position: relative; 
        width: 70px; 
        height: 70px; 
        overflow: hidden; 
        border-radius: 50%; 
        /* margin-top: 15px; */
    }
    .comment-section h4{
        font-size: 1rem;
        /* border: solid 2px purple; */
        padding-top: 5px;
        padding-left: 10px;
        font-weight:700;
    }
    .comment-section h4 span{
        font-size: 1.4rem;
        font-weight: 800;
    }
    .comment-section h4 p{
        font-size: 0.9rem;
        font-weight: 400;
    }

    .picture4-section img{
        margin-top: 20px;
        width: 450px;
        height: 460px;
    }
    .text-section h3{
        margin-top: 30px;
        margin-left: 30px;
        font-size: 1.8rem;
        text-align: start;
    }
    .text-section h5{
        margin-top: 30px;
        margin-left: 10px;
        text-align: start;
        font-size: 1rem;
        font-weight: 350;
        padding-left: 20px;
        padding-right: 20px;  
    }
    .text-section h5 i{
        font-weight: bold;
    }

    .picture2-section img{
        margin-top: 30px;
        width: 690px;
        height: 400px;
    }

    .text2-section h5{
        margin-top: 30px;
        margin-left: 5px;
        text-align: start;
        font-size: 1rem;
        font-weight: 350;
        padding-left: 20px;
        padding-right: 20px; 
        margin-bottom: 20px;  
    }

    .text2-section i{
    cursor: pointer;
    color: blue;  
    }
    .border6-comment{
        border-top: solid 1px blue;
        text-align: start;  
    }
    .border6-comment h6{
        font-size: 0.8rem;
        font-weight: 700;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px; 
    }

    .sign-director{
        text-align: start;
    }
}
@media screen and (width > 1600px){
    .post1-section{
        border: solid 1px rgb(177, 177, 177);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 30px;
    }
    .comment-section{
        display: flex;
        flex-direction: row;
        /* border: solid 2px red; */
        margin-top: 20px;  
        text-align: start;
        margin-left: 20px;
    }
    .comment-section img{
        display: inline-block; 
        position: relative; 
        width: 70px; 
        height: 70px; 
        overflow: hidden; 
        border-radius: 50%; 
        /* margin-top: 15px; */
    }
    .comment-section h4{
        font-size: 1rem;
        /* border: solid 2px purple; */
        padding-top: 5px;
        padding-left: 10px;
        font-weight:700;
    }
    .comment-section h4 span{
        font-size: 1.4rem;
        font-weight: 800;
    }
    .comment-section h4 p{
        font-size: 0.9rem;
        font-weight: 400;
    }

    .picture4-section img{
        margin-top: 20px;
        width: 550px;
        height: 680px;
    }
    .text-section h3{
        margin-top: 30px;
        margin-left: 30px;
        font-size: 1.8rem;
        text-align: start;
    }
    .text-section h5{
        margin-top: 30px;
        margin-left: 10px;
        text-align: start;
        font-size: 1rem;
        font-weight: 350;
        padding-left: 20px;
        padding-right: 20px;  
    }
    .text-section h5 i{
        font-weight: bold;
    }

    .picture2-section img{
        margin-top: 30px;
        width: 690px;
        height: 400px;
    }

    .text2-section h5{
        margin-top: 30px;
        margin-left: 5px;
        text-align: start;
        font-size: 1rem;
        font-weight: 350;
        padding-left: 20px;
        padding-right: 20px; 
        margin-bottom: 20px;  
    }

    .text2-section i{
    cursor: pointer;
    color: blue;  
    }
    .border6-comment{
        border-top: solid 1px blue;
        text-align: start;  
    }
    .border6-comment h6{
        font-size: 0.8rem;
        font-weight: 700;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px; 
    }

    .sign-director{
        text-align: start;
    }
}