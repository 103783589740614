.body-online{
    margin:0;
    margin-top: 30px;
    padding: 0;
    height: 100vh;
    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #fff;
    /*background-image: linear-gradient(50deg, #abdbdb 0%, #067570 120%);*/
}