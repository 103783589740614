.breadcrumb-ol{
  background-color: white;
  border-radius: 0.1rem;
  padding: 15px 0px;

}
.breadcrumb-ol{
  display: flex;
  list-style: none;
  
}
.button-breadcrumbs{
  flex: 1;
  margin-left: 10px;
  border: none;
  background-color: white;
  color: rgb(10, 57, 248);
  cursor: pointer;
  list-style: none;
  font-size: 18px;
}

.button-breadcrumbs-disable{
  flex: 1;
  margin-left: 10px;
  
  border: none;
  background-color: white;
  color: rgb(10, 57, 248);
  cursor: not-allowed;
  list-style: none;  
  font-size: 18px;
}


.button-breadcrumbs:hover{
  background-color: #01959a;
  color: #fff;
  border-radius: 4px;
  padding: 10px;
  list-style: none;
}

@media screen and (max-width:1280px) {
  .button-breadcrumbs{
    font-size: 14px;
  }
  
  .button-breadcrumbs-disable{
    font-size: 14px;
  }

}