.scroll-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 80%;
  width: 40%;
  justify-items: center;
}

.bread-flex{
  flex: 1;
  border: 2px rgb(239, 13, 96) solid;
  
}

.list-flex{
  flex: 12;
  border: 2px blue solid;
  overflow-y: scroll;
  
}