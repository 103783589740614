.dashboard-container{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 88%;
    background-color: rgb(253, 251, 251); 
   
}
.module-flex{
    flex: 1.6;
    margin: 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    justify-items: center;
    border: 1px solid rgb(216, 212, 212);
    /* background-color: #f7f3ef; */
    border-radius: 15px;
}
.breadcrumb-flex{
    flex: 1;    
    border-bottom: 1px solid rgb(216, 212, 212); 
    margin: 0px 10px;
}
.listed-flex{
    flex: 12;
    overflow-y: scroll;   
    /* border: solid 2px red; */
    margin: 10px 5px; 
}
.blackboard-flex{
    flex: 3;
    margin: 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    border: 1px solid rgb(216, 212, 212);
    /* background-color: #f7f3ef; */
    border-radius: 15px;
}
.blackboard-font{
    color: rgb(8, 87, 235);
    font-weight: 400;
    font-size: 18px;
    text-align: start;
    padding: 18px;    
}
.displayed-flex{   
    flex: 12;    
    /* border: solid 2px red; */
    overflow-y: scroll;
    margin: 0px 20px;
    display: flex;
    flex-direction: column;
    align-items: end;
    border-top: 1px solid rgb(216, 212, 212);  
    background-color: #f7f3ef;
}
.ayah-flex{
    font-size: 32px;
    padding: 5px 10px;
    margin: 5px 15px;
    /* background-color: #dcf8c6; */
    background-color: white;
    border-radius: 13px; 
    text-align: end;
    border: 1px solid rgb(8, 87, 235);
    white-space: pre-wrap; 
}
.time-flex{
    font-size: 12px;
    text-align: start;   
}
.sentmessage-flex{
    flex: 3;
    border-top: 1px solid rgb(216, 212, 212);  
    margin: 10px 20px;
    display: flex;
    flex-direction: row;
    justify-content:  space-between;
}
.message-flex{
    flex: 8;
    padding: 1rem;
    border-radius: 0.3rem;
    border: solid 0.8px #2a2a2a;
    font-size: 2.1rem; 
    margin-top: 10px;   
}
.button-flex{
    flex: 1;
    margin: 50px 0px 50px 10px;
    font-size: 20px;
    font-weight: bold;
    border: none;
    border-radius: 10px;
    background-color:bisque;
    box-shadow: 5px 5px 10px 0px rgb(42, 42, 215);
    cursor: pointer;
}
.chat-flex{
    flex: 1.6;
    margin: 0px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    border: 1px solid rgb(216, 212, 212);
   
    border-radius: 15px; 
}

.chat-font{
    color: rgb(8, 87, 235);
    font-weight: 400;
    font-size: 18px;
    text-align: start;
    padding: 18px; 
}
.content-chat{
    flex: 3.6;
    /* border: solid 2px red; */
    overflow-y: scroll;
    margin: 0px 10px;
    display: flex;
    flex-direction: column;
    align-items: end;
    border: 1px solid rgb(216, 212, 212);
    background-color: #f7f3ef;
}
.chatreceived-flex{
    font-size: 16px;
    padding: 5px 10px;
    margin: 5px 15px;
    background-color: white;
    /* background-color: #f7f3ef; */
    border-radius: 13px; 
    text-align: start;
    border: 1px solid rgb(9, 235, 164);
    white-space: pre-wrap;
}
.chattime-flex{
    font-size: 12px;
    text-align: start;   
    font-weight: bold;
    white-space: pre-wrap;
}
.attendees-font{
    color: rgb(8, 87, 235);
    font-weight: 400;
    font-size: 18px;
    text-align: start;
    padding: 18px 18px 0px 18px;
}
.atttend-flex{
    flex: 1;
    /* border: solid 2px red; */
    overflow-y: scroll;
    margin: 10px 10px;
    border: 1px solid rgb(216, 212, 212);
    background-color: #f7f3ef; 
}
.list-joined {
    text-align: start;
    list-style-type: none;
    padding: 8px 12px;
    font-weight: none;
}
.list-joined p{
    font-size: 0.9rem;
    font-weight: 500;
}

@media screen and (max-width:1280px) {
    .dashboard-container{
        width: 100%;
        height: 86%;
        margin-top: 0px;
    }
    .blackboard-font{
        font-size: 16px;
        padding: 16px;    
    }
    .chat-font{
        font-size: 16px;
        padding: 16px; 
    }
    .attendees-font{
        font-size: 16px;
        padding: 16px 16px 0px 16px;
    }
    
    .displayed-flex{
        margin: 0px 10px;
    }
    .sentmessage-flex{
        margin: 5px 10px;
    }
    .message-flex{
        flex: 10;
        font-size: 1.1rem;    
    }
    .button-flex{
        flex: 1;
        margin: 40px 0px 40px 10px;
        font-size: 13px;
        padding: 5px;
    }
    .listed-flex{
        flex: 90;
        overflow-y: scroll;    
    }
}

@media screen and (max-width: 378px){
    .dashboard-container{
        display: flex;
        flex-direction: column;  
        margin-left: 1px;
        margin-top: 210%;
        align-items: center;   
        width: 100%;
        height: 200%;
    }
    .module-flex{
        flex: 1;
        width: 95%; 
        margin: 10px 10px;  
    }
    .blackboard-flex{
        width: 95%;
        height: 100%;   
    }
    .chat-flex{
        flex: 1;  
        width: 95%; 
        height: 100%;
        margin: 10px 0px;
    }
    
    
}

@media screen and (min-width: 380px) and (max-width: 450px){
    .dashboard-container{
        display: flex;
        flex-direction: column;  
        margin-left: 1px;
        margin-top: 240%;
        align-items: center;   
        width: 100%;
        height: 200%;
    }
    .module-flex{
        flex: 1;
        width: 95%; 
        margin: 10px 10px;  
    }
    .blackboard-flex{
        width: 95%;
        height: 100%;   
    }
    .chat-flex{
        flex: 1;  
        width: 95%; 
        height: 100%;
        margin: 10px 0px;
    }
}
@media screen and (min-width: 451px) and (max-width: 860px) {
    .dashboard-container{
        display: flex;
        flex-direction: column;  
        margin-left: 1px;
        margin-top: 155%;
        align-items: center;   
        width: 100%;
        height: 200%;
    }
    .module-flex{
        flex: 1;
        width: 95%; 
        margin: 10px 10px;  
    }
    
    .blackboard-flex{
        width: 95%;
        height: 100%;   
    }
    .chat-flex{
        flex: 1;  
        width: 95%; 
        height: 100%;
        margin: 10px 0px;
    }
    
    .chat-container{
        width: 95%;
        height: 60%;
        margin: 10px 10px;
        font-weight: bold;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 5px 15px 0 black;
    }
    .chat{
        width: 95%;
        height: 20%;
        margin: 10px 10px;
    }
}



