@media screen and (width <= 480px){
    .from-container{
        justify-content: center;
        align-items: center;
        text-align: center;
        /* border: solid 1px red; */
        margin-top: 3.5rem;
        margin-left: 10px;
        margin-right: 10px;
    }
    .from-container h1{
        font-size: 1.6rem;
    }
    .all-post {
        display: flex;
        justify-content: start;

    }
    .all-post h2{
        font-size: 1.1rem;
        text-align: start;
        margin-bottom: 10px;
        cursor: pointer;
        /* border: solid 1px rgb(177, 177, 177); */
    }
    .fa-share{
        color: black;
        padding: 5px;
        cursor: pointer;
        transform: scaleX(-1);
        -moz-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        -ms-transform: scaleX(-1); 
    }

    .meet-news-first{
        margin-top: 5px;
        margin-bottom: -15px;
        font-size: 1.6rem;
        padding-top: 5px;
        padding-bottom: 5px;
        /* border: #2a2a2a solid 1px; */
        color: black;
        background-color: #FFD700;
        max-width: 100%;
        text-decoration: none;
    }
    .post-section{
        border: solid 1px rgb(177, 177, 177);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 25px;
    }
    .post-image{
        flex: 2;
        /* cursor: pointer;  */
    }
    .post-image img{
        width: 320px;
        height: 200px;
        object-fit:fill;
        margin-top: 15px;
        
        
    }
    .post-image1 img{
        width: 400px;
        height: 450px;
        object-fit:fill;
        margin-bottom: -8px;
    }

    .post-text{
        /* border: solid 2px blue; */
        flex: 2.3; 
        text-align: start;
        padding-left: 20px;
        padding-right: 10px;
    }
    .see-section{
        display: flex;
        flex-direction: row;
        justify-content: start;
        text-align: start;
        /* border: solid 2px red; */
        margin-top: 20px;  
    }
    .see-section img{
        display: inline-block; 
        position: relative; 
        width: 70px; 
        height: 70px; 
        overflow: hidden; 
        border-radius: 50%; 
    }
    .see-section h4{
        font-size: 1rem;
        /* border: solid 2px purple; */
        padding-top: 5px;
        padding-left: 10px;
        font-weight:700;
        
    }
    .see-section h4 span{
        font-size: 1.4rem;
        font-weight: 800;
    }
    .see-section h4 p{
        font-size: 0.9rem;
        font-weight: 400;
    }

    .no-line{
        text-decoration: none;
        color: black;
        cursor: pointer;  
    }

    .see-no-line h3{
        padding-right: 3px;  
    }

    .post-text h1{
        font-size: 1.3rem;
    }
    .post-text h3{
        margin-top: 30px;
        font-size: 1.5rem;
    }
    .post-text h5{
        margin-top: 20px;
        font-size: 1rem;
        font-weight: 370;
        margin-bottom: 10px;
    }
    .see-border{
        border-top: solid 1px blue;
        
        
        
    }
    .see-border h6{
        font-size: 0.8rem;
        font-weight: 700;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
        
    }
    .meet-news{
        margin-top: 30px;
        font-size: 2.1rem;
        padding-top: 5px;
        padding-bottom: 5px;
        /* border: #2a2a2a solid 1px; */
        color: black;
        background-color: #FFD700;
        max-width: 100%;
        text-decoration: none;

    }
    .no-line .title-bold{
        font-weight: bold;
    }
}
@media screen and (480px < width <= 768px){
    .from-container{
        justify-content: center;
        align-items: center;
        text-align: center;
        /* border: solid 1px red; */
        margin-top: 3.5rem;
        margin-left: 10px;
        margin-right: 10px;
    }
    .from-container h1{
        font-size: 1.6rem;
    }
    .all-post {
        display: flex;
        justify-content: start;

    }
    .all-post h2{
        font-size: 1.1rem;
        text-align: start;
        margin-bottom: 10px;
        cursor: pointer;
        /* border: solid 1px rgb(177, 177, 177); */
    }
    .fa-share{
        color: black;
        padding: 5px;
        cursor: pointer;
        transform: scaleX(-1);
        -moz-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        -ms-transform: scaleX(-1); 
    }

    .meet-news-first{
        margin-top: 5px;
        margin-bottom: -15px;
        font-size: 1.6rem;
        padding-top: 5px;
        padding-bottom: 5px;
        /* border: #2a2a2a solid 1px; */
        color: black;
        background-color: #FFD700;
        max-width: 100%;
        text-decoration: none;
    }
    .post-section{
        border: solid 1px rgb(177, 177, 177);
        display: flex;
        flex-direction: row;
        
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;
        margin-bottom: 5px;
    }
    .post-image{
        flex: 2.1;
        /* cursor: pointer;  */
        /* border: solid 2px blue; */
        align-items: center;
       
    }
    .post-image img{
        width: 320px;
        height: 370px;
        object-fit:fill;
        /* border: solid 2px blue; */
        
        
    }
    .post-image1 img{
        width: 320px;
        height: 370px;
        object-fit:fill;
        /* margin-bottom: -8px; */
        
    }

    .post-text{
        
        flex: 2.3; 
        text-align: start;
        padding-left: 20px;
        padding-right: 10px;
    }
    .see-section{
        display: flex;
        flex-direction: row;
        justify-content: start;
        text-align: start;
        /* border: solid 2px red; */
        margin-top: 20px;  
    }
    .see-section img{
        display: inline-block; 
        position: relative; 
        width: 70px; 
        height: 70px; 
        overflow: hidden; 
        border-radius: 50%; 
       
    }
    .see-section h4{
        font-size: 1rem;
        /* border: solid 2px purple; */
        padding-top: 5px;
        padding-left: 10px;
        font-weight:700;
        
    }
    .see-section h4 span{
        font-size: 1.4rem;
        font-weight: 800;
    }
    .see-section h4 p{
        font-size: 0.9rem;
        font-weight: 400;
    }

    .no-line{
        text-decoration: none;
        color: black;
        cursor: pointer;  
    }

    .see-no-line h3{
        padding-right: 3px;  
    }

    .post-text h1{
        font-size: 1.3rem;
    }
    .post-text h3{
        margin-top: 30px;
        font-size: 1.5rem;
    }
    .post-text h5{
        margin-top: 20px;
        font-size: 1rem;
        font-weight: 370;
        margin-bottom: 10px;
    }
    .see-border{
        border-top: solid 1px blue;
        
        
        
    }
    .see-border h6{
        font-size: 0.8rem;
        font-weight: 700;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
        
    }
    .meet-news{
        margin-top: 30px;
        font-size: 2.1rem;
        padding-top: 5px;
        padding-bottom: 5px;
        /* border: #2a2a2a solid 1px; */
        color: black;
        background-color: #FFD700;
        max-width: 100%;
        text-decoration: none;

    }
    .no-line .title-bold{
        font-weight: bold;
    }
}
@media screen and (768px < width <= 1024px){
    .from-container{
        justify-content: center;
        align-items: center;
        text-align: center;
        /* border: solid 1px red; */
        margin-top: 3.5rem;
        margin-left: 10px;
        margin-right: 10px;
    }
    .from-container h1{
        font-size: 1.6rem;
    }
    .all-post {
        display: flex;
        justify-content: start;

    }
    .all-post h2{
        font-size: 1.1rem;
        text-align: start;
        margin-bottom: 10px;
        cursor: pointer;
        /* border: solid 1px rgb(177, 177, 177); */
    }
    .fa-share{
        color: black;
        padding: 5px;
        cursor: pointer;
        transform: scaleX(-1);
        -moz-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        -ms-transform: scaleX(-1); 
    }

    .meet-news-first{
        margin-top: 5px;
        margin-bottom: -15px;
        font-size: 1.6rem;
        padding-top: 5px;
        padding-bottom: 5px;
        /* border: #2a2a2a solid 1px; */
        color: black;
        background-color: #FFD700;
        max-width: 100%;
        text-decoration: none;
    }
    .post-section{
        border: solid 1px rgb(177, 177, 177);
        display: flex;
        flex-direction: row;
        
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .post-image{
        flex: 2;
        /* cursor: pointer;  */
        /* border: solid 2px blue; */
        align-items: center;
       
    }
    .post-image img{
        width: 420px;
        height: 370px;
        object-fit:fill;
        /* border: solid 2px blue; */
        
        
    }
    .post-image1 img{
        width: 320px;
        height: 370px;
        object-fit:fill;
        /* margin-bottom: -8px; */
        
    }

    .post-text{
        
        flex: 2.3; 
        text-align: start;
        padding-left: 20px;
        padding-right: 10px;
    }
    .see-section{
        display: flex;
        flex-direction: row;
        justify-content: start;
        text-align: start;
        /* border: solid 2px red; */
        margin-top: 20px; 
         
    }
    .see-section img{
        display: inline-block; 
        position: relative; 
        width: 70px; 
        height: 70px; 
        overflow: hidden; 
        border-radius: 50%; 
       
    }
    .see-section h4{
        font-size: 1rem;
        /* border: solid 2px purple; */
        padding-top: 5px;
        padding-left: 10px;
        font-weight:700;
        
    }
    .see-section h4 span{
        font-size: 1.4rem;
        font-weight: 800;
    }
    .see-section h4 p{
        font-size: 0.9rem;
        font-weight: 400;
    }

    .no-line{
        text-decoration: none;
        color: black;
        cursor: pointer;  
    }

    .see-no-line h3{
        padding-right: 3px;  
    }

    .post-text h1{
        font-size: 1.3rem;
    }
    .post-text h3{
        margin-top: 30px;
        font-size: 1.5rem;
    }
    .post-text h5{
        margin-top: 20px;
        font-size: 1rem;
        font-weight: 370;
        margin-bottom: 10px;
    }
    .see-border{
        border-top: solid 1px blue;
        
        
        
    }
    .see-border h6{
        font-size: 0.8rem;
        font-weight: 700;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
        
    }
    .meet-news{
        margin-top: 30px;
        font-size: 2.1rem;
        padding-top: 5px;
        padding-bottom: 5px;
        /* border: #2a2a2a solid 1px; */
        color: black;
        background-color: #FFD700;
        max-width: 100%;
        text-decoration: none;

    }
    .no-line .title-bold{
        font-weight: bold;
    }
}

@media screen and (1024px < width <=1200px){
    .from-container{
        justify-content: center;
        align-items: center;
        text-align: center;
        /* border: solid 1px red; */
        margin-top: 3.5rem;
        margin-left: 10px;
        margin-right: 10px;
    }
    .from-container h1{
        font-size: 1.6rem;
    }
    .all-post {
        display: flex;
        justify-content: start;

    }
    .all-post h2{
        font-size: 1.1rem;
        text-align: start;
        margin-bottom: 10px;
        cursor: pointer;
        /* border: solid 1px rgb(177, 177, 177); */
    }
    .fa-share{
        color: black;
        padding: 5px;
        cursor: pointer;
        transform: scaleX(-1);
        -moz-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        -ms-transform: scaleX(-1); 
    }

    .meet-news-first{
        margin-top: 5px;
        margin-bottom: -15px;
        font-size: 1.6rem;
        padding-top: 5px;
        padding-bottom: 5px;
        /* border: #2a2a2a solid 1px; */
        color: black;
        background-color: #FFD700;
        max-width: 100%;
        text-decoration: none;
    }
    .post-section{
        border: solid 1px rgb(177, 177, 177);
        display: flex;
        flex-direction: row;
        
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .post-image{
        flex: 2;
        /* cursor: pointer;  */
        /* border: solid 2px blue; */
        align-items: center;
       
    }
    .post-image img{
        width: 420px;
        height: 370px;
        object-fit:fill;
        /* border: solid 2px blue; */
        
        
    }
    .post-image1 img{
        width: 320px;
        height: 370px;
        object-fit:fill;
        /* margin-bottom: -8px; */
        
    }

    .post-text{
        
        flex: 2.3; 
        text-align: start;
        padding-left: 20px;
        padding-right: 10px;
    }
    .see-section{
        display: flex;
        flex-direction: row;
        justify-content: start;
        text-align: start;
        /* border: solid 2px red; */
        margin-top: 20px; 
         
    }
    .see-section img{
        display: inline-block; 
        position: relative; 
        width: 70px; 
        height: 70px; 
        overflow: hidden; 
        border-radius: 50%; 
       
    }
    .see-section h4{
        font-size: 1rem;
        /* border: solid 2px purple; */
        padding-top: 5px;
        padding-left: 10px;
        font-weight:700;
        
    }
    .see-section h4 span{
        font-size: 1.4rem;
        font-weight: 800;
    }
    .see-section h4 p{
        font-size: 0.9rem;
        font-weight: 400;
    }

    .no-line{
        text-decoration: none;
        color: black;
        cursor: pointer;  
    }

    .see-no-line h3{
        padding-right: 3px;  
    }

    .post-text h1{
        font-size: 1.3rem;
    }
    .post-text h3{
        margin-top: 30px;
        font-size: 1.5rem;
    }
    .post-text h5{
        margin-top: 20px;
        font-size: 1rem;
        font-weight: 370;
        margin-bottom: 10px;
    }
    .see-border{
        border-top: solid 1px blue;
        
        
        
    }
    .see-border h6{
        font-size: 0.8rem;
        font-weight: 700;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
        
    }
    .meet-news{
        margin-top: 30px;
        font-size: 2.1rem;
        padding-top: 5px;
        padding-bottom: 5px;
        /* border: #2a2a2a solid 1px; */
        color: black;
        background-color: #FFD700;
        max-width: 100%;
        text-decoration: none;

    }
    .no-line .title-bold{
        font-weight: bold;
    }
}
@media screen and (1200px < width <= 1280px){
    .from-container{
        justify-content: center;
        align-items: center;
        text-align: center;
        /* border: solid 1px red; */
        margin-top: 5rem;
        margin-left: 200px;
        margin-right: 200px;
    }
    .from-container h1{
        font-size: 2rem;
    }
    .all-post {
        display: flex;
        justify-content: start;

    }
    .all-post h2{
        font-size: 1.1rem;
        text-align: start;
        margin-bottom: 10px;
        cursor: pointer;
        /* border: solid 1px rgb(177, 177, 177); */
    }
    .fa-share{
        color: black;
        padding: 5px;
        cursor: pointer;
        transform: scaleX(-1);
        -moz-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        -ms-transform: scaleX(-1); 
    }

    .meet-news-first{
        margin-top: 5px;
        margin-bottom: -15px;
        font-size: 1.6rem;
        padding-top: 5px;
        padding-bottom: 5px;
        /* border: #2a2a2a solid 1px; */
        color: black;
        background-color: #FFD700;
        max-width: 100%;
        text-decoration: none;
    }
    .post-section{
        border: solid 1px rgb(177, 177, 177);
        display: flex;
        flex-direction: row;
        
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .post-image{
        flex: 2;
        /* cursor: pointer;  */
        /* border: solid 2px blue; */
        align-items: center;
       
    }
    .post-image img{
        width: 420px;
        height: 370px;
        object-fit:fill;
        /* border: solid 2px blue; */
        
        
    }
    .post-image1 img{
        width: 320px;
        height: 370px;
        object-fit:fill;
        /* margin-bottom: -8px; */
        
    }

    .post-text{
        
        flex: 2.3; 
        text-align: start;
        padding-left: 20px;
        padding-right: 10px;
    }
    .see-section{
        display: flex;
        flex-direction: row;
        justify-content: start;
        text-align: start;
        /* border: solid 2px red; */
        margin-top: 20px; 
         
    }
    .see-section img{
        display: inline-block; 
        position: relative; 
        width: 70px; 
        height: 70px; 
        overflow: hidden; 
        border-radius: 50%; 
       
    }
    .see-section h4{
        font-size: 1rem;
        /* border: solid 2px purple; */
        padding-top: 5px;
        padding-left: 10px;
        font-weight:700;
        
    }
    .see-section h4 span{
        font-size: 1.4rem;
        font-weight: 800;
    }
    .see-section h4 p{
        font-size: 0.9rem;
        font-weight: 400;
    }

    .no-line{
        text-decoration: none;
        color: black;
        cursor: pointer;  
    }

    .see-no-line h3{
        padding-right: 3px;  
    }

    .post-text h1{
        font-size: 1.3rem;
    }
    .post-text h3{
        margin-top: 30px;
        font-size: 1.5rem;
    }
    .post-text h5{
        margin-top: 20px;
        font-size: 1rem;
        font-weight: 370;
        margin-bottom: 10px;
    }
    .see-border{
        border-top: solid 1px blue;
        
        
        
    }
    .see-border h6{
        font-size: 0.8rem;
        font-weight: 700;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
        
    }
    .meet-news{
        margin-top: 30px;
        font-size: 2.1rem;
        padding-top: 5px;
        padding-bottom: 5px;
        /* border: #2a2a2a solid 1px; */
        color: black;
        background-color: #FFD700;
        max-width: 100%;
        text-decoration: none;

    }
    .no-line .title-bold{
        font-weight: bold;
    }
}

@media screen and (1280px < width <= 1600px){
    .from-container{
        justify-content: center;
        align-items: center;
        text-align: center;
        /* border: solid 1px red; */
        margin-top: 5rem;
        margin-left: 200px;
        margin-right: 200px;
    }
    .from-container h1{
        font-size: 2rem;
    }
    .all-post {
        display: flex;
        justify-content: start;

    }
    .all-post h2{
        font-size: 1.1rem;
        text-align: start;
        margin-bottom: 10px;
        cursor: pointer;
        /* border: solid 1px rgb(177, 177, 177); */
    }
    .fa-share{
        color: black;
        padding: 5px;
        cursor: pointer;
        transform: scaleX(-1);
        -moz-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        -ms-transform: scaleX(-1); 
    }

    .meet-news-first{
        margin-top: 5px;
        margin-bottom: -15px;
        font-size: 1.6rem;
        padding-top: 5px;
        padding-bottom: 5px;
        /* border: #2a2a2a solid 1px; */
        color: black;
        background-color: #FFD700;
        max-width: 100%;
        text-decoration: none;
    }
    .post-section{
        border: solid 1px rgb(177, 177, 177);
        display: flex;
        flex-direction: row;
        
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .post-image{
        flex: 2;
        /* cursor: pointer;  */
        /* border: solid 2px blue; */
        align-items: center;
       
    }
    .post-image img{
        width: 420px;
        height: 370px;
        object-fit:fill;
        /* border: solid 2px blue; */
        
        
    }
    .post-image1 img{
        width: 320px;
        height: 370px;
        object-fit:fill;
        /* margin-bottom: -8px; */
        
    }

    .post-text{
        
        flex: 2.3; 
        text-align: start;
        padding-left: 20px;
        padding-right: 10px;
    }
    .see-section{
        display: flex;
        flex-direction: row;
        justify-content: start;
        text-align: start;
        /* border: solid 2px red; */
        margin-top: 20px; 
         
    }
    .see-section img{
        display: inline-block; 
        position: relative; 
        width: 70px; 
        height: 70px; 
        overflow: hidden; 
        border-radius: 50%; 
       
    }
    .see-section h4{
        font-size: 1rem;
        /* border: solid 2px purple; */
        padding-top: 5px;
        padding-left: 10px;
        font-weight:700;
        
    }
    .see-section h4 span{
        font-size: 1.4rem;
        font-weight: 800;
    }
    .see-section h4 p{
        font-size: 0.9rem;
        font-weight: 400;
    }

    .no-line{
        text-decoration: none;
        color: black;
        cursor: pointer;  
    }

    .see-no-line h3{
        padding-right: 3px;  
    }

    .post-text h1{
        font-size: 1.3rem;
    }
    .post-text h3{
        margin-top: 30px;
        font-size: 1.5rem;
    }
    .post-text h5{
        margin-top: 20px;
        font-size: 1rem;
        font-weight: 370;
        margin-bottom: 10px;
    }
    .see-border{
        border-top: solid 1px blue;
        
        
        
    }
    .see-border h6{
        font-size: 0.8rem;
        font-weight: 700;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
        
    }
    .meet-news{
        margin-top: 30px;
        font-size: 2.1rem;
        padding-top: 5px;
        padding-bottom: 5px;
        /* border: #2a2a2a solid 1px; */
        color: black;
        background-color: #FFD700;
        max-width: 100%;
        text-decoration: none;

    }
    .no-line .title-bold{
        font-weight: bold;
    }
}
@media screen and (width > 1600px){
    .from-container{
        justify-content: center;
        align-items: center;
        text-align: center;
        /* border: solid 1px red; */
        margin-top: 5rem;
        margin-left: 490px;
        margin-right: 490px;
    }
    .from-container h1{
        font-size: 2rem;
    }
    .all-post {
        display: flex;
        justify-content: start;

    }
    .all-post h2{
        font-size: 1.1rem;
        text-align: start;
        margin-bottom: 10px;
        cursor: pointer;
        /* border: solid 1px rgb(177, 177, 177); */
    }
    .fa-share{
        color: black;
        padding: 5px;
        cursor: pointer;
        transform: scaleX(-1);
        -moz-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        -ms-transform: scaleX(-1); 
    }

    .meet-news-first{
        margin-top: 5px;
        margin-bottom: -15px;
        font-size: 1.6rem;
        padding-top: 5px;
        padding-bottom: 5px;
        /* border: #2a2a2a solid 1px; */
        color: black;
        background-color: #FFD700;
        max-width: 100%;
        text-decoration: none;
    }
    .post-section{
        border: solid 1px rgb(177, 177, 177);
        display: flex;
        flex-direction: row;
        
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .post-image{
        flex: 2;
        /* cursor: pointer;  */
        /* border: solid 2px blue; */
        align-items: center;
       
    }
    .post-image img{
        width: 420px;
        height: 370px;
        object-fit:fill;
        /* border: solid 2px blue; */
        margin: 0;
        padding: 0;
        margin-bottom: -8px;
        
        
    }
    .post-image1 img{
        width: 320px;
        height: 370px;
        object-fit:fill;
        
        
    }

    .post-text{
        
        flex: 2.3; 
        text-align: start;
        padding-left: 20px;
        padding-right: 10px;
    }
    .see-section{
        display: flex;
        flex-direction: row;
        justify-content: start;
        text-align: start;
        /* border: solid 2px red; */
        margin-top: 20px; 
    }
    .see-section img{
        display: inline-block; 
        position: relative; 
        width: 70px; 
        height: 70px; 
        overflow: hidden; 
        border-radius: 50%; 
       
    }
    .see-section h4{
        font-size: 1rem;
        /* border: solid 2px purple; */
        padding-top: 5px;
        padding-left: 10px;
        font-weight:700;
        
    }
    .see-section h4 span{
        font-size: 1.4rem;
        font-weight: 800;
    }
    .see-section h4 p{
        font-size: 0.9rem;
        font-weight: 400;
    }

    .no-line{
        text-decoration: none;
        color: black;
        cursor: pointer;  
    }

    .see-no-line h3{
        padding-right: 3px;  
    }

    .post-text h1{
        font-size: 1.3rem;
    }
    .post-text h3{
        margin-top: 30px;
        font-size: 1.5rem;
    }
    .post-text h5{
        margin-top: 20px;
        font-size: 1rem;
        font-weight: 370;
        margin-bottom: 10px;
    }
    .see-border{
        border-top: solid 1px blue;
         
    }
    .see-border h6{
        font-size: 0.8rem;
        font-weight: 700;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
        
    }
    .meet-news{
        margin-top: 30px;
        font-size: 2.1rem;
        padding-top: 5px;
        padding-bottom: 5px;
        /* border: #2a2a2a solid 1px; */
        color: black;
        background-color: #FFD700;
        max-width: 100%;
        text-decoration: none;

    }
    .no-line .title-bold{
        font-weight: bold;
    }
}
