
.login-container {
    border: solid 1.5px black;
    background-color: white;
    padding: 30px 80px;
    border-radius: 10px;
    box-shadow: 0 5px 15px 0 black; 
    align-items: center;
}

.login-container h1{
    color: #FFD700;
    font-size: 2.5rem; 
}

.login-container p{
    color: black;
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 30px;
}

.login-container form{
    
    align-items: center;
}

.login-container input{
    
    border: 1.9px solid #009e97 !important;
    font-size: 16px;
    text-align: center;
    padding: 12px 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 1rem;
}

.login-container select{
    
    border: 1.9px solid #009e97 !important;
    font-size: 18px;
    font-weight:bold;
    text-align: center;
    padding: 9px 75px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 1rem;
    text-align: center;
}


.login-container button{
    padding: 4px 100px;
    margin-left: 0;
    margin-right: 0;
    margin-top: 20px ;
    margin-bottom: 30px;
    background-image: linear-gradient(to right, #8c36a5 50%, #8c36a5 50%, #e1b3f0);
    background-size: 200%;
    background-position: 0%;
    transition: 0.4s;
    color: white;
    border-radius: 1rem;
    border: none;
    font-size: 18px;
    
}
.login-container button:hover{
    background-position: 100% 0%;
}


@media screen and (max-width:1280px) {
    .login-container {
        padding: 10px 50px;
    }
    .login-container h1{
        font-size: 2.5rem; 
    }
    .login-container p{
        font-size: 1.1rem;
    }
    .login-container input{ 
        font-size: 14px;
        padding: 8px 25px;
        margin-top: 0px;
        margin-bottom: 10px;
    }
    .login-container button{
        padding: 4px 90px;
        font-size: 16px;  
    }
    .login-container select{
       
        font-size: 13px;
        padding: 8px 75px;
        margin-top: 0px;
        margin-bottom: 10px;
    }
        
}

@media screen and (max-width:860px) {
    .login-container select{
       
        font-size: 13px;
        padding: 8px 53px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .login-container {
        padding: 10px 30px;
    }
    .login-container h1{
        font-size: 2rem; 
    }
    .login-container p{
        font-size: 1.1rem;
    }
    .login-container input{ 
        font-size: 14px;
        padding: 8px 0px;
        margin-top: 8px;
        margin-bottom: 10px;
    }
    .login-container button{
        padding: 4px 80px;
        font-size: 16px;  
    }    
}

.errorUsername{
    color: red;
    text-align: center;
}
