.listlevel1-container{
    list-style: none;
    
}
.listlevel1-body{
    
    text-align: start;
    margin: 10px 10px;
    
}

.listlevel1-button{
    color: rgb(10, 57, 248);
    border: none;
    background-color: rgb(171, 168, 170);
    width: 100%;
    padding: 5px;
    text-align: start;
    font-size: 15px;
}
.listlevel1-button:hover{
    background-color: #01959a;
    color: #fff;
    border-radius: 4px;
    padding: 10px;
    list-style: none;
    cursor: pointer;
}