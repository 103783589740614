@media screen and (width <= 480px){
    .first-test{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        margin-top: 6rem; 
        margin-left: 1rem;
        margin-right: 1rem;
        /* border: solid red 2px; */
    }
    .test-text {
        text-align: start;
        font-size: 1.1rem;  
        /* border: solid blue 2px; */
        flex: 1; 
    }
    .test-text h2 {
        padding-bottom: 1rem;
    }
    .test-text p {
        padding-bottom: 1rem;
    }
    .test-image {
        flex: 1;
    } 
    .test-image img {
        width: 300px;
        height: 300px;
        border-radius: 10px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
    }
    .first-test-reverse{
        
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        margin-top: 4rem; 
        margin-left: 2rem;
        margin-right: 2rem;
        margin-bottom: 1rem;  
    }
    .first-test-reverse .test-image{
        flex: 1;
        text-align: start;
    }
    .first-test-reverse .test-image img {
        width: 300px;
        height: 300px;
        border-radius: 10px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
        margin-left: -20px;
    }


    .first-test-third{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        margin-top: 5rem;
        margin-left: 2rem;
        margin-right: 2rem;
        margin-bottom: 6rem;     
    }

    .first-test-third .test-image img {
        width: 300px;
        height: 300px;
        border-radius: 10px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
    }
}

@media screen and (480px < width <= 768px){ 
    .first-test{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        margin-top: 6rem; 
        margin-left: 2rem;
        margin-right: 2rem;
    }
    .test-text {
        text-align: start;
        font-size: 1.1rem;  
        /* border: solid blue 2px; */
        flex: 1; 
    }
    .test-text h2 {
        padding-bottom: 1rem;
    }
    .test-text p {
        padding-bottom: 1rem;
    }
    .test-image {
        flex: 1;
        text-align: center;
    } 
    .test-image img {
        width:  500px;
        height: 400px;
        /* border-radius: 5px; */
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
    }
    .first-test-reverse{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        margin-top: 4rem; 
        margin-left: 2rem;
        margin-right: 2rem;
        margin-bottom: 1rem; 
        /* border: solid red 1px;      */
    }
    .first-test-reverse .test-image{
        flex: 1;
        text-align: start;
        margin-left: -64px;
    }
    .first-test-reverse .test-image img {
        width: 500px;
        height: 400px;
        /* border-radius: 5px; */
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
        margin-left: -20px;
    }

    .first-test-third{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        margin-top: 5rem;
        margin-left: 2rem;
        margin-right: 2rem;
        margin-bottom: 6rem;  
        /* border: solid red 1px;       */
    }

    .first-test-third .test-image img {
        width: 500px;
        height: 400px;
        /* border-radius: 5px; */
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
    }
}

@media screen and (768px < width <= 1024px){
    .first-test{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        margin-top: 6rem; 
        margin-left: 2rem;
        margin-right: 2rem;
        /* border: solid red 2px;     */
    }
    .test-text {
        text-align: start;
        font-size: 1.1rem;  
        /* border: solid blue 2px; */
        flex: 1; 
    }
    .test-text h2 {
        padding-bottom: 1rem;
    }
    .test-text p {
        padding-bottom: 1rem;
    }
    .test-image {
        flex: 1;
        text-align: center;
    } 
    .test-image img {
        width:  500px;
        height: 400px;
        border-radius: 15px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
    }
    .first-test-reverse{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        margin-top: 4rem; 
        margin-left: 2rem;
        margin-right: 2rem;
        margin-bottom: 1rem; 
        /* border: solid red 1px;      */
    }
    .first-test-reverse .test-image{
        flex: 1;
        text-align: start;
    }
    .first-test-reverse .test-image img {
        width: 500px;
        height: 400px;
        border-radius: 15px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
        margin-left: -20px;
    }

    .first-test-third{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        margin-top: 5rem;
        margin-left: 2rem;
        margin-right: 2rem;
        margin-bottom: 6rem;  
        /* border: solid red 1px;       */
    }

    .first-test-third .test-image img {
        width: 500px;
        height: 400px;
        border-radius: 15px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
    }
}

@media screen and (1024px < width <=1200px){
    .first-test{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        margin-top: 6rem; 
        margin-left: 2rem;
        margin-right: 2rem;
        /* border: solid red 2px;     */
    }
    .test-text {
        text-align: start;
        font-size: 1.1rem;  
        /* border: solid blue 2px; */
        flex: 1; 
        padding: 10px;
    }
    .test-text h2 {
        padding-bottom: 1rem;
    }
    .test-text p {
        padding-bottom: 1rem;
    }
    .test-image {
        flex: 1;
        text-align: center;
    } 
    .test-image img {
        width:  600px;
        height: 400px;
        /* border-radius: 5px; */
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
    }
    .first-test-reverse{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        margin-top: 4rem; 
        margin-left: 2rem;
        margin-right: 2rem;
        margin-bottom: 1rem; 
        /* border: solid red 1px;      */
    }
    .first-test-reverse .test-image{
        flex: 1;
        text-align: start;
        margin-left: -64px;
    }
    .first-test-reverse .test-image img {
        width: 600px;
        height: 400px;
        /* border-radius: 5px; */
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
        margin-left: -20px;
    }

    .first-test-third{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        margin-top: 5rem;
        margin-left: 2rem;
        margin-right: 2rem;
        margin-bottom: 6rem;  
        /* border: solid red 1px;       */
    }

    .first-test-third .test-image img {
        width: 600px;
        height: 400px;
        /* border-radius: 5px; */
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
    }
}


@media screen and (1200px < width <= 1600px){
    .first-test{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 6rem; 
        margin-left: 2rem;
        margin-right: 2rem;
        margin-bottom: 6rem;
        /* border: solid red 2px;     */
    }
    .test-text {
        text-align: start;
        font-size: 1.1rem;  
        /* border: solid blue 2px; */
        flex: 1; 
        padding-left: 100px;
    }
    .test-text h2 {
        padding-bottom: 1rem;
    }
    .test-text p {
        padding-bottom: 1rem;
        /* border: solid blue 1px; */
    }
    .test-image {
        flex: 1;
        text-align: center;
    } 
    .test-image img {
        width:  450px;
        height: 400px;
        border-radius: 10px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
    }
    .first-test-reverse{
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        margin-top: 4rem; 
        margin-left: 2rem;
        margin-right: 2rem;
        margin-bottom: 1rem; 
        /* border: solid red 1px;      */
    }
    .first-test-reverse .test-image{
        flex: 1;
        text-align: center;
    }
    .first-test-reverse .test-image img {
        width: 450px;
        height: 400px;
        /* border-radius: 5px; */
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
        /* margin-left: -20px; */
    }

    .first-test-reverse .test-text h2 {
        padding-bottom: 1rem;
        padding-left: 4rem;
        margin-left: -9rem;
    }
    .first-test-reverse .test-text p {
        padding-bottom: 1rem;
        padding-right: 4rem;
        margin-left: -5rem;
        /* border: solid blue 1px; */
    }

    .first-test-third{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 6rem;
        margin-left: 2rem;
        margin-right: 2rem;
        margin-bottom: 6rem;  
        /* border: solid red 1px;       */
    }

    .first-test-third .test-image img {
        width: 450px;
        height: 400px;
        /* border-radius: 5px; */
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
    }
}

@media screen and (width > 1600px){
    .first-test{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 6rem; 
        margin-left: 2rem;
        margin-right: 2rem;
        margin-bottom: 6rem;
        /* border: solid red 1px; */
    }
    .test-text {
        text-align: start;
        font-size: 1.1rem;  
        /* border: solid blue 2px; */
        flex: 1; 
        padding-left: 100px;
    }
    .test-text h2 {
        padding-bottom: 1rem;
        padding-left: 4rem;
    }
    .test-text p {
        padding-bottom: 1rem;
        padding-right: 4rem;
        padding-left: 4rem;
        /* border: solid blue 1px; */
    }
    .test-image {
        flex: 1;
        text-align: center;
    } 
    .test-image img {
        width:  600px;
        height: 400px;
        border-radius: 10px;
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
    }
    .first-test-reverse{
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        margin-top: 4rem; 
        margin-left: 2rem;
        margin-right: 2rem;
        margin-bottom: 1rem; 
        /* border: solid red 1px;      */
    }
    .first-test-reverse .test-image{
        flex: 1;
        text-align: center;
    }
    .first-test-reverse .test-image img {
        width: 600px;
        height: 400px;
        /* border-radius: 5px; */
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
        /* margin-left: -20px; */
    }

    .first-test-reverse .test-text h2 {
        padding-bottom: 1rem;
        padding-left: 4rem;
        margin-left: -8rem;
    }
    .first-test-reverse .test-text p {
        
        padding-bottom: 1rem;
        padding-right: 4rem;
        margin-left: -8rem;
        
        /* border: solid blue 1px; */
    }

    .first-test-third{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 6rem;
        margin-left: 2rem;
        margin-right: 2rem;
        margin-bottom: 6rem;  
        /* border: solid red 1px;       */
    }

    .first-test-third .test-image img {
        width: 600px;
        height: 400px;
        /* border-radius: 5px; */
        box-shadow: -1px 1px 62px -18px rgba(0,0,0,0.19);
    }
}